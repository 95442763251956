import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import wordTypeList from '../../components/dropdowns/WordType'
import difficultyList from '../../components/dropdowns/DifficultyLevel'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function VocabularyGeneratorForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [language, setLanguage] = useState('');
  const [wordType, setWordtype] = useState('');
  const [difficulty, setDifficulty] = useState('')
  const [wordcount, setWordCount] = useState('');
  const [theme, setTheme] = useState('');
  const serviceId = "vocabulary generator";

  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      language,
      wordType,
      difficulty,
      wordcount,
      theme,
      
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, difficulty, wordType, language})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, difficulty, wordType, language})
        if(error.response.data === "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
          <TextField name="language" label="Specify the Language for which you need Vocabulary Words" onChange={e => setLanguage(e.target.value)} />
          <FormControl fullWidth>
            <InputLabel id="wordtype-label">Word Type</InputLabel>
            <Select labelId="wordtype-label" value={wordType} label="Word Type" onChange={e => setWordtype(e.target.value)}>
              {wordTypeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="difficulty-label">Difficulty Level</InputLabel>
            <Select labelId="difficulty-label" value={difficulty} label="Difficulty Level" onChange={e => setDifficulty(e.target.value)}>
              {difficultyList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField name="wordcount" label="Specify the number of vocabulary words to generate" onChange={e => setWordCount(e.target.value)} />
          <TextField name="theme" label="(Optional) Enter the Theme or Context for the Vocabulary Words" multiline="true" onChange={e => setTheme(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
