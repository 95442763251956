import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation } from 'react-router-dom';
import useResponsive from '../hooks/useResponsive';
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import AppLoader from '../components/loader/AppLoader';
import useLoader from '../hooks/useLoader';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { AppMoreMenu } from '../sections/@dashboard/app'
import {Box,Table,TableBody,TableHead,TableCell,TableContainer,TableFooter,TablePagination,TableRow,Alert,
    styled,Grid,Container,Typography,Button,TextField,Dialog,DialogActions,DialogContent,
    DialogTitle,Autocomplete} from '@mui/material';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}));

function TablePaginationActions(props) {

  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const columns = [
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email'},
  { id: 'phone', label: 'Phone' },
  { id: 'active_status', label: 'Active Status' },
  { id: 'action', label: 'Action'},
  { id: 'more', label: ''},
];


export default function Users() {
    const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const mdUp = useResponsive('up', 'md');
  const user_id = localStorage.getItem("inktype");
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null)
  const [open, setOpen] = useState(false);
  const [unit, setUnit] = useState('');
  const { setLoading, setServerResponse, loading, serverResponse } = useLoader();
  const [searchTerm, setSearchTerm] = useState('')

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    retrieveUsers()
    setIsLoading(true)
  }, [loading]);


  const handleClickOpen = (id) => {
    localStorage.setItem("action_user_id", id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const retrieveUsers = async () => {
    try {
      const response = await axiosPrivate.get(`/profile/users`);
      setUserData(response.data) 
      setIsLoading(false)
    } catch (error) {
      if(error.response?.status == 401){
        setIsLoading(false)
        navigate('/login', {state: {from: location}, replace: true})
      }
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault()
    const id = localStorage.getItem("action_user_id");
    setLoading(true)
    axiosPrivate.put(`/transactions/topup-user/${id}`, { 
      unit, 

      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      setServerResponse(result)         
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      setServerResponse(error.response.data)   
      if(error.response.data == "Forbidden"){
        navigate('/login', {state: {from: location}, replace: true})
      }
    });
    setOpen(false);
  };


  const handleSearch = async (query) => {
    try {
      const response = await axiosPrivate.get(`/profile/user/search/${query}`);
      setUserData(response.data) 
    } catch (error) {
      console.log(error.response)
    }
  };

  return (
    <>
        {!isLoading && 
        <>
            <Helmet>
            <title> Dashboard: Users </title>
            </Helmet>

            {mdUp && (
                <StyledSection>
                    <Typography variant="h3" sx={{ px: 3}}>
                        All Users
                    </Typography>
                </StyledSection>
            )}

            <Container>
                <Grid container spacing={12}>
                    <Grid item xs={12} md={12}>
                        {serverResponse != null && <Alert severity="info">{serverResponse}</Alert>}
                        <TableContainer component={Paper}>
                          <Grid container spacing={1}>
                            <Grid item xs={8} md={9}>
                              {userData
                                ?
                                  <Autocomplete
                                    id="search"
                                    freeSolo
                                    options={userData.map((option) => option.name)}
                                    renderInput={(params) => <TextField {...params} onBlur={e => setSearchTerm(e.target.value) } label="Search By Name" />}
                                  />
                                :
                                null
                              }
                            </Grid>
                            <Grid item xs={4} md={3}>
                              <Button variant="contained" color="success" onClick={() => handleSearch(searchTerm)} style={{ padding: 12 }}>
                                Search
                              </Button>
                            </Grid>
                          </Grid>
                            
                            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                <TableHead>
                                  <TableRow>
                                    {columns.map((column) => (
                                      <TableCell
                                        key={column.id}
                                      >
                                        {column.label}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                {(rowsPerPage > 0
                                    ? userData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : userData
                                )?.map((row) => (
                                    <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell>
                                        {row.email}
                                    </TableCell>
                                    <TableCell>
                                        {row.phone}
                                    </TableCell>
                                    <TableCell>
                                        {row.active_status}
                                    </TableCell>
                                    <TableCell align="right">
                                    <Button variant="contained" onClick={() => handleClickOpen(row.id)}>
                                        Add Unit
                                    </Button>
                                    </TableCell>
                                    <TableCell align="right">
                                      <AppMoreMenu user={row.id} user_status={row.active_status} />
                                    </TableCell>
                                    </TableRow>
                                ))}
                                
                                </TableBody>
                                <TableFooter>
                                <TableRow>
                                  {userData ?
                                      <TablePagination
                                        rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
                                        colSpan={3}
                                        count={userData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                            'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                      />
                                    :
                                      null
                                  }
                                </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>            
                    </Grid>
                </Grid>
            </Container>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle> Unit Topup</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Unit Value"
                        type="number"
                        fullWidth
                        variant="standard"
                        onChange={e => setUnit(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
        }

        {isLoading &&
        <AppLoader />
        }
    </>
  );
}
