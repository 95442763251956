import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import useAuth from '../../../hooks/useAuth';
import useLoader from '../../../hooks/useLoader';
import axios from '../../../api/axios'


// ----------------------------------------------------------------------

const ForgotPasswordForm = () => {
  const { setLoading, setServerResponse } = useLoader();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  
  const [email, setEmail] = useState();

  const handleSubmit = async () => {
    localStorage.setItem("resetpasswordemail", email);
    setLoading(true)
    try {
      const response = await axios.get(`/auth/checkemail/${email}`);

        setServerResponse(null)
        setLoading(false)

        if(response.data === "Found"){
          navigate('/verifytoken')
        }    
    } catch (error) { 
        console.log(error.response.data);
        setServerResponse(error.response.data)
        setLoading(false)
    }
  };


  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Enter Email Address" 
        autoComplete="off"
        onChange={e => setEmail(e.target.value)} />

        <LoadingButton fullWidth size="large" type="button" variant="contained" onClick={handleSubmit}>
            Submit
        </LoadingButton>       
      </Stack>
    </>
  );
}

export default ForgotPasswordForm
