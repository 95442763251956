import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select,Grid,Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/iconify';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import questionTypeList from '../../components/dropdowns/QuestionType'
import gradeList from '../../components/dropdowns/Grade'
import contentLengthList from '../../components/dropdowns/ContentLength'
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function AssessmentForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [subject, setSubject] = useState('');
  const [grade, setGrade] = useState('');
  const [description, setDescription] = useState('');
  const [noOfQuestion, setnoOfQuestion] = useState('');
  const [type, setType] = useState('');
  const [topic1, setTopic1] = useState('');
  const [topic2, setTopic2] = useState('');
  const [topic3, setTopic3] = useState('');
  const [topic1QuestionNo, setTopic1QuestionNo] = useState(1);
  const [topic2QuestionNo, setTopic2QuestionNo] = useState(1);
  const [topic3QuestionNo, setTopic3QuestionNo] = useState(1);
  const [showTopic2, setShowTopic2] = useState(false);
  const [showTopic3, setShowTopic3] = useState(false);
  const topicOne = topic1 != '' ? topic1QuestionNo + ' from ' + topic1 + ', ' : null
  const topicTwo = topic2 != '' ? topic2QuestionNo + ' from ' + topic2 + ', ' : null
  const topicThree = topic3 != '' ? topic3QuestionNo + ' from ' + topic3 + ', ' : null
  const topic = topicOne + topicTwo + topicThree
  const serviceId = "assessment questions generator";

  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      subject,
      grade,
      noOfQuestion,
      type,
      description,
      topic,

      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
        const result = res.data
        props.onSubmit({result, subject, grade, type})
        setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };

  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, subject, grade, type})
        if(error.response.data == "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };
  

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
          <TextField name="subject" label="Subject" onChange={e => setSubject(e.target.value)} />
          <Grid container>
            <Grid item xs={12} md={7} sx={{ pr: 1, pb:1}}>
              <TextField name="topic1" label="Topic" onChange={e => setTopic1(e.target.value)} style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} md={5} sx={{ pb: 1}}>
              <TextField name="topic_qn1" label="No. Of Question" onChange={e => setTopic1QuestionNo(e.target.value)} style={{ width: '100%' }} />
            </Grid>

            {showTopic2 &&
              <>
                <Grid item xs={12} md={7} sx={{ pr: 1, pb:1}}>
                  <TextField name="topic2" label="Topic2" onChange={e => setTopic2(e.target.value)} style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={12} md={5} sx={{pb:1}}>
                  <TextField name="topic_qn2" label="No. Of Question" onChange={e => setTopic2QuestionNo(e.target.value)} style={{ width: '100%' }} />
                </Grid>
              </>
            }

            {showTopic3 &&
              <>
                <Grid item xs={12} md={7} sx={{ pr: 1, pb:1}}>
                  <TextField name="topic3" label="Topic3" onChange={e => setTopic3(e.target.value)} style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={12} md={5} sx={{pb:1}}>
                  <TextField name="topic_qn3" label="No. Of Question" onChange={e => setTopic3QuestionNo(e.target.value)} style={{ width: '100%' }} />
                </Grid>
              </>
            }

            {!showTopic2 && <Button variant="outlined" onClick={() => setShowTopic2(true)}>Add New Topic</Button> }
            {!showTopic3 && showTopic2 && <Button variant="outlined" onClick={() => setShowTopic3(true)}>Add New Topic</Button> }
          </Grid>

          <FormControl fullWidth>
            <InputLabel id="grade-label">Grade</InputLabel>
            <Select labelId="grade-label" value={grade} label="Grade Level" onChange={e => setGrade(e.target.value)}>
              {gradeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField name="noOfQuestion" label="Number of Question" onChange={e => setnoOfQuestion(e.target.value)} />
          <FormControl fullWidth>
            <InputLabel id="questiontype-label">Queston Type</InputLabel>
            <Select labelId="questiontype-label" value={type} label="Queston Type" onChange={e => setType(e.target.value)}>
              {questionTypeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField name="description" label="Include any additional notes, guidelines, or special considerations" multiline="true" onChange={e => setDescription(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
