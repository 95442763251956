import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select, 
  FormLabel,FormGroup,FormControlLabel,Checkbox} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import gradeList from '../../components/dropdowns/Grade'
import designStyleList from '../../components/dropdowns/DesignStyle'
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function PowerpointSlideForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [subject, setSubject] = useState('');
  const [grade, setGrade] = useState('');
  const [topic, setTopic] = useState('');
  const [numberOfSlides , setNumberOfSlides] = useState(5);
  const [keyPoints, setKeyPoints] = useState('');
  const [designStyle, setDesignStyle] = useState('');
  const [includeImage, setIncludeImage] = useState(true);
  const [notes, setNotes] = useState('');
  const serviceId = "power point slide";

  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      subject,
      grade,
      topic,
      numberOfSlides,
      keyPoints,
      designStyle,
      includeImage,
      notes,

      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, subject, grade, topic})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, subject, grade, topic})
        if(error.response.data == "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
            <TextField name="subject" label="Enter Subject (Math, Science, History)" onChange={e => setSubject(e.target.value)} />
          <FormControl fullWidth>
            <InputLabel id="grade-label">Grade Level</InputLabel>
            <Select labelId="grade-label" value={grade} label="Grade Level" onChange={e => setGrade(e.target.value)}>
              {gradeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <TextField name="topic" label="Enter Topic" onChange={e => setTopic(e.target.value)} />
          <TextField name="numberOfSlides" label="Number Of Slides" onChange={e => setNumberOfSlides(e.target.value)} />
          {/* <TextField name="keyPoints" label="Key Points for Each Slide" multiline="true" onChange={e => setKeyPoints(e.target.value)} /> */}
          
          {/* <FormControl fullWidth>
            <InputLabel id="grade-label">Design Style</InputLabel>
            <Select labelId="Design-Style-label" value={designStyle} label="Design Style" onChange={e => setDesignStyle(e.target.value)}>
              {designStyleList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          {/* <FormControl>
            <FormControlLabel
                control={<Checkbox checked={includeImage} onChange={e => setIncludeImage(e.target.value)} name="includeImage" />}
                label="Include Images?"
            />
          </FormControl> */}

          {/* <TextField name="notes" label="Additional Notes/Instructions" multiline="true" onChange={e => setNotes(e.target.value)} /> */}
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
