import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function WritingAssistantForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();
  
  const [topic, setTopic] = useState('');
  const [audience, setAudience] = useState('');
  const [purpose, setPurpose] = useState('');
  const [tone, setTone] = useState('');
  const [keyPoints, setKeyPoints] = useState('');
  const [length, setLength] = useState('');
  const serviceId = "writing assistant";

  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      topic,
      audience,
      purpose,
      tone,
      keyPoints,
      length,

      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, purpose, audience, topic})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, purpose, audience, topic})
        if(error.response.data === "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };
  
  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
          <TextField name="title" label="Enter Topic or Subject" onChange={e => setTopic(e.target.value)} />
          <TextField name="audience" label="Indicate the target audience (e.g., students, parents, colleagues)" multiline="false" onChange={e => setAudience(e.target.value)} />
          <TextField name="purpose" label="Describe the purpose of the writing (e.g. lesson plan, newsletter, announcement)" multiline="false" onChange={e => setPurpose(e.target.value)} />
          <TextField name="tone" label="Specify the desired tone of the writing (e.g., formal, informal, instructional)" multiline="false" onChange={e => setTone(e.target.value)} />
          <TextField name="keyPoints" label="Provide key points or information to be included in the writing" multiline="true" onChange={e => setKeyPoints(e.target.value)} />
          <TextField name="length" label="Specify the desired length of the content" multiline="true" onChange={e => setLength(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
