import { useState } from 'react';
import FormData from 'form-data';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, Alert, Input, InputLabel,MenuItem,FormControl,Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import gradeList from '../../components/dropdowns/Grade'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function AudioToTextForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [errorMessage, setErrorMessage] = useState(null)
  const [description, setDescription] = useState('');
  const [audio, setAudio] = useState(null);
  const serviceId = "transcribe";

  const handleSubmit = () => {
    setLoading(true)

    let formData = new FormData();
    formData.append('description', description)
    formData.append('audio', audio)
    formData.append('user_id', auth?.userId)
    formData.append('serviceId', serviceId)

    axiosPrivate.post(`/ai/teacher`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
        const result = res.data
        props.onSubmit({result, description})
        setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      // navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, description})
        if(error.response.data == "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ['audio/wav','audio/mpeg','audio/webm','audio/mp3','audio/x-m4a','video/mp4','video/webm','video/wav','video/mpeg'];
      if (allowedTypes.includes(file.type)) {
        setErrorMessage(null)
        setAudio(file);
      } else {
        setErrorMessage("Invalid file type. Allowed types: mp3, mp4, mpeg, mpga, m4a, wav, and webm")
        event.target.value = null; 
      }
    }
  }

  return (
    <>
      {errorMessage && <Alert mb={2} severity="error">{errorMessage}</Alert> }
      <form onSubmit={checkAccount}>
        <Stack spacing={3} mb={4}>
          <Input name="audio" label="Upload Audio File" type="file" variant="filled" onChange={handleFileChange} 
          accept=".mp3, .mp4, .mpeg, .mpga, .m4a, .wav, .webm" />
        </Stack>
        <Stack spacing={3} mb={4}>
          <TextField name="description" label="Include any additional notes, guidelines, or special considerations" multiline="true" onChange={e => setDescription(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Transcribe
        </LoadingButton>
      </form>
    </>
  );
}
