import React from 'react'
import Lottie from "lottie-react";
import { styled } from '@mui/material/styles';
import * as loader from '../../loader.json'

const StyledContent = styled('div')(({ theme }) => ({
    position:'relative',
    backgroundColor: 'rgba(0,0,0,0.1)',
    zIndex: 1,
    width:'100%', 
    height:'100%',
}));

const StyledContentIcon = styled('div')(({ theme }) => ({
  position:'absolute',
  left:0,
  right:0,
  top:0,
  bottom:0,
  margin:'auto',
  zIndex: 1,
  width:150, 
  height:150,
}));

const AppLoader = () => {

    return (
      <StyledContent>
        <StyledContentIcon>
          <Lottie 
            animationData={loader}
          />
        </StyledContentIcon>
      </StyledContent>
    )
}

export default AppLoader
