const PackageList = [
    /* {
      id: '2',
      title: 'Silver',
      subtitle: 'Five seats available',
      unit: '50',
      code: '#223377',
      currencyNGN: 'NGN',
      pricef: '5000',
      currencyUSD: 'USD',
      pricefUSD: '7'
    },
    {
      id: '3',
      title: 'Gold',
      subtitle: 'Ten seats available',
      unit: '100',
      code: '#de2633',
      currencyNGN: 'NGN',
      pricef: '10000',
      currencyUSD: 'USD',
      pricefUSD: '15'
    },
    {
      id: '4',
      title: 'Plantinum',
      subtitle: 'Unlimited seats available',
      unit: '200',
      code: '#829309',
      currencyNGN: 'NGN',
      pricef: '20000',
      currencyUSD: 'USD',
      pricefUSD: '30'
    },
    {
      id: '5',
      title: 'Organization Termly Plan',
      subtitle: 'Unlimited seats available',
      unit: '90', // 90 days
      code: '#829309',
      currencyNGN: 'NGN',
      pricef: '120000',
      currencyUSD: 'USD',
      pricefUSD: '150'
    },
    {
      id: '6',
      title: 'Organization Yearly Plan',
      subtitle: 'Unlimited seats available',
      unit: '365', // 365 days
      code: '#829309',
      currencyNGN: 'NGN',
      pricef: '350000',
      currencyUSD: 'USD',
      pricefUSD: '440'
    }, */

    {
      id: 1,
      title: 'Free',
      subtitle: 'No seat available',
      unit: 'unlimited',
      code: '#223377',
      currencyNGN: '₦',
      pricef: '0',
      currencyUSD: '$',
      pricefUSD: '0',
      type:"Free"
    },
    {
      id: 2,
      title: 'Pro - (Monthy)',
      subtitle: 'Unlimited seats available',
      unit: '100',
      code: '#de2633',
      currencyNGN: '₦',
      pricef: 5000,
      currencyUSD: '$',
      pricefUSD: 5,
      type:"Monthly"
    },
    {
      id: 3,
      title: 'Pro - (Yearly)',
      subtitle: 'Unlimited seats available',
      unit: '100',
      code: '#de2633',
      currencyNGN: '₦',
      pricef: 55000,
      currencyUSD: '$',
      pricefUSD: 50,
      type:"Yearly"
    },
];

export default PackageList