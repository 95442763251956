import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useLocation } from 'react-router-dom';
import useResponsive from '../hooks/useResponsive';
import { TexttoSpeechForm } from '../sections/forms';
import { AppSaveResources } from '../sections/@dashboard/app';
import useCurrentPlan from '../hooks/useCurrentPlan';
import AppLoader from '../components/loader/AppLoader';
import useLoader from '../hooks/useLoader';
import { AppSubscriptionCall } from '../sections/@dashboard/app';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

// ----------------------------------------------------------------------
  
  const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  }));

  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 680,
    margin: 'auto',
    minHeight: '5vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  }));

  const StyledContentResult = styled('div')(({ theme }) => ({
    margin: 'auto',
    minHeight: 180,
    height:180,
    padding: theme.spacing(2, 5),
    borderWidth: 2,
    borderColor:'#BFBEC0',
    borderStyle:'solid',
    marginTop:'40px',
    marginBottom:'20px',
  }));

  
  // ----------------------------------------------------------------------

export default function TexttoSpeechGenerator() {
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');
  const [isLoading, setIsLoading] = useState(false);
  const { loading } = useLoader();
  const axiosPrivate = useAxiosPrivate()
  const user_id = localStorage.getItem("inktype");
  const { currentplan, setCurrentPlan } = useCurrentPlan();

  const serviceId = "text to speech";
  const [result, setResult] = useState(null)
  const [subject, setSubject] = useState(null)
  const [grade, setGrade] = useState(null)
  const [title, setTitle] = useState(null)

  useEffect(() => {
    setIsLoading(loading)
  }, [loading]);


  useEffect(() => {
    retrieveWallet()
  })


  const getData = ({result, voiceType, audioName, text}) => {
    setResult(result)
    setSubject(audioName)
    setGrade(voiceType)
    setTitle(text)
  }


  const retrieveWallet = async () => {
    try {
      const response = await axiosPrivate.get(`/profile/wallet/${user_id}`);
      setCurrentPlan(response.data[0].name)
    } catch (error) {
      if(error.response?.status == 401){
        //console.log("Error: ", error.response)
      }
    }
  };

    return (
      <>
        {!isLoading && 
          <>
            <Helmet>
              <title> Dashboard: Text to Speech Generator  </title>
            </Helmet>
    
            {mdUp && (
              <StyledSection>
                <Typography variant="h3" sx={{ px: 3}}>
                Text to Speech Generator
                </Typography>
              </StyledSection>
            )}
      
            <Container>
              {currentplan === 'Free' ? (
                
                <AppSubscriptionCall />

              ) : (
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <StyledContent>
                      <TexttoSpeechForm onSubmit={getData} />
                    </StyledContent>
                  </Grid>
      
                  <Grid item xs={12} md={6}>
                    <StyledContentResult>
                      { result && <div id="resultDoc">
                        <audio controls>
                          <source src={result} type="audio/ogg" />
                          <source src={result} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      </div> }
                      { !result && <h3>Result:</h3>}
                    </StyledContentResult>
                    
                  </Grid>
                </Grid>
              )}
            </Container>
          </>
        }

        {isLoading &&
          <AppLoader />
        }
      </>
      
    );
  }
  