import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import contentLengthList from '../../components/dropdowns/ContentLength'
import appraisalTypeList from '../../components/dropdowns/AppraisalType'
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function AppraisalForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [studentDetails, setStudentDetails] = useState('');
  const [aDate, setADate] = useState('');
  const [type, setType] = useState('');
  const [strengths, setStrengths] = useState('');
  const [specificCriteria, setSpecificCriteria] = useState('');
  const [comments, setComments] = useState('');
  const [areasforImprovement, setAreasforImprovement] = useState('');
  const [recommendations, setRecommendations] = useState('');
  const serviceId = "appraisal generator";
  
  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      studentDetails,
      aDate,
      type,
      strengths,
      areasforImprovement,
      recommendations,
      comments,
      specificCriteria,

      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, type, specificCriteria, studentDetails})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, type, specificCriteria, studentDetails})
        if(error.response.data === "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
            <TextField name="StudentDetails" label="Enter Student the Name or Identifier" multiline="true" onChange={e => setStudentDetails(e.target.value)} />
            <TextField name="date" label="Enter Date of the Feedback" onChange={e => setADate(e.target.value)} />
            <FormControl fullWidth>
                <InputLabel id="feedback-type-label">Feedback Type</InputLabel>
                <Select labelId="feedback-type-label" value={type} label="Feedback Type" onChange={e => setType(e.target.value)}>
                {appraisalTypeList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>

            <TextField name="SpecificCriteria" label="Criteria (e.g. communication skills, problem-solving abilities)" multiline="true" onChange={e => setSpecificCriteria(e.target.value)} />
            <TextField name="Strengths" label="Enter the Student Areas of Strength" multiline="true" onChange={e => setStrengths(e.target.value)} />
            <TextField name="Improvement" label="Enter Areas for Improvement" multiline="true" onChange={e => setAreasforImprovement(e.target.value)} />
            <TextField name="Improvement" label="Indicate if you want space for Additional Feedback" multiline="true" onChange={e => setComments(e.target.value)} />
            <TextField name="Recommendations" label="Suggest Actionable Recommendations for the Student's Growth" multiline="true" onChange={e => setRecommendations(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
