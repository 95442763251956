import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {Button,TextField,Dialog,DialogActions,DialogContent,DialogTitle} from '@mui/material';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useLoader from '../../../hooks/useLoader';

AppPasswordReset.propTypes = {
    user: PropTypes.number,
};

export default function AppPasswordReset({user}) {
  const navigate = useNavigate();
  const [isopen, setIsOpen] = useState(false);
  const [password, setPassword] = useState('')
  const [cfpassword, setCFPassword] = useState('')
  const axiosPrivate = useAxiosPrivate()
  const { setServerResponse } = useLoader();

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleDialogSubmit = async () => {
    try {
        const response = await axiosPrivate.put(`/auth/resetpassword/${user}`, {
          password: password,
          password_confirmation: cfpassword
        });
        setServerResponse(response.data)
        handleDialogClose()
      } catch (error) {
          // console.log(error.response)
          setServerResponse(error.response.data)
          handleDialogClose()
    }
  }


  return (
    <>
        <Button variant="contained" onClick={() => handleClickOpen()}>
            Reset Password
        </Button>

        <Dialog open={isopen} onClose={handleDialogClose}>
            <DialogTitle> Enter New Password</DialogTitle>
                <DialogContent>
                    <TextField name="password" label="Password" onChange={e => setPassword(e.target.value)} /> <br /><br />
                    <TextField name="cfpassword" label="Confirm Password" onChange={e => setCFPassword(e.target.value)} />
                </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button variant="contained" onClick={() => { handleDialogSubmit()}}> Submit </Button>
            </DialogActions>
        </Dialog>
    </>
  );
}
