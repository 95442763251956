import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { styled } from '@mui/material/styles';
import { Grid, Button, Container, Stack, Typography, Alert } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import useResponsive from '../hooks/useResponsive';
import { TeamAddForm } from '../sections/forms';
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import AppLoader from '../components/loader/AppLoader';
import useLoader from '../hooks/useLoader';
import { AppTeamMembers } from '../sections/@dashboard/app';


// ----------------------------------------------------------------------
  
  const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }));

  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 780,
    margin: 'auto',
    minHeight: '5vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(5, 0),
  }));

  const StyledContentData = styled('div')(({ theme }) => ({
    margin: 'auto',
    minHeight: 480,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 5),
  }));
  
  // ----------------------------------------------------------------------

export default function Teams() {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const mdUp = useResponsive('up', 'md');
  const user_id = localStorage.getItem("inktype");
  const [isLoading, setIsLoading] = useState(false);
  const [resultStatus, setResultStatus] = useState(false);
  const [result, setResult] = useState(null)
  const [teamData, setTeamData] = useState(null)
  
  const [displayForm, setDisplayForm] = useState(false);
  const { loading, serverResponse } = useLoader();

  useEffect(() => {
    retrieveTeam()
    setIsLoading(loading)
  }, [loading]);

  const getData = ({result, status}) => {
    setResult(result)
    setResultStatus(status)
  }

  const retrieveTeam = async () => {
    try {
      const response = await axiosPrivate.get(`/team/myteammembers/${user_id}`);
      setTeamData(response.data) 
    } catch (error) {
      if(error.response?.status == 401){
        navigate('/login', {state: {from: location}, replace: true})
      }
    }
  };

    return (
      <>
        {!isLoading && 
          <>
            <Helmet>
              <title> Dashboard: Team </title>
            </Helmet>
    
            {mdUp && (
                <StyledSection>
                    <Typography variant="h3" sx={{ px: 3}}>
                        My Team
                    </Typography>

                    <Button variant="contained" onClick={() => setDisplayForm(!displayForm)}>
                        Add Member
                    </Button>
              </StyledSection>
            )}
    
            <Container>
              <Grid container spacing={12}>
                <Grid item xs={12} md={12}>
                    {displayForm && 
                        <Grid item xs={12} md={12}>
                            <StyledContent>
                                {result && !resultStatus && <Alert severity="error">{result}</Alert>}
                                {result && resultStatus && <Alert severity="success">{result}</Alert>}
                                <TeamAddForm onSubmit={getData} />
                            </StyledContent>
                        </Grid>
                    }
        
                    <Grid item xs={12} md={12}>
                        <StyledContentData>
                        {serverResponse != null && <Alert severity="info">{serverResponse}</Alert>}
                        {teamData  
                        ?
                            <AppTeamMembers
                                title="My Team Members"
                                list={teamData.map((item, index) => ({
                                    id: item.id,
                                    title: item.firstname +' '+ item.lastname,
                                    description: item.email,
                                    image: `/assets/images/covers/cover_${index + 1}.jpg`,
                                }))}
                            />
                        :
                            <AppTeamMembers
                                title="No Team Member Found"
                                list={[]}
                            />
                        }
                        </StyledContentData>                        
                    </Grid>
                </Grid>
              </Grid>
            </Container>
          </>
        }

        {isLoading &&
          <AppLoader />
        }
      </>
      
    );
  }
  