import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useLocation } from 'react-router-dom';
import JsPDF from 'jspdf';
import html2canvas from "html2canvas";
import Iconify from '../components/iconify';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../sections/@dashboard/blog';
import useResponsive from '../hooks/useResponsive';
import { SdgIntegrationForm } from '../sections/forms';
import { AppSaveResources } from '../sections/@dashboard/app';
import AppLoader from '../components/loader/AppLoader';
import useLoader from '../hooks/useLoader';

// ----------------------------------------------------------------------
  
  const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  }));

  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 680,
    margin: 'auto',
    minHeight: '5vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  }));

  const StyledContentResult = styled('div')(({ theme }) => ({
    margin: 'auto',
    minHeight: 480,
    height:480,
    padding: theme.spacing(2, 5),
    borderWidth: 2,
    borderColor:'#BFBEC0',
    borderStyle:'solid',
    marginTop:'40px',
    marginBottom:'20px',
    overflowY:'scroll',
  }));

  
  // ----------------------------------------------------------------------

export default function SdgGenerator() {
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');
  const [isLoading, setIsLoading] = useState(false);
  const { loading } = useLoader();

  const serviceId = "coach sport practice";
  const [result, setResult] = useState(null)
  const [subject, setSubject] = useState(null)
  const [grade, setGrade] = useState(null)
  const [title, setTitle] = useState(null)


  useEffect(() => {
    setIsLoading(loading)
  }, [loading]);

  const getData = ({result, sdg, grade, subject}) => {
    setResult(result)
    setSubject(subject)
    setGrade(grade)
    setTitle(sdg)
  }



    return (
      <>
        {!isLoading && 
          <>
            <Helmet>
              <title> Dashboard: Sustainable Development Goals Generator </title>
            </Helmet>
    
            {mdUp && (
              <StyledSection>
                <Typography variant="h3" sx={{ px: 3}}>
                    Sustainable Development Goals Generator
                </Typography>
              </StyledSection>
            )}
      
            <Container>
              <Grid container spacing={4}>
                <Grid item xs={12} md={5}>
                  <StyledContent>
                    <SdgIntegrationForm onSubmit={getData} />
                  </StyledContent>
                </Grid>
    
                <Grid item xs={12} md={7}>
                  <StyledContentResult>
                    { result && <div id="resultDoc"><ReactMarkdown>{result}</ReactMarkdown></div> }
                    { !result && <h3>Result:</h3>}
                  </StyledContentResult>
                  
                  {result &&
                    <Grid container spacing={12} py={3}>
                      <Grid item xs={6} md={4}>
                        <Button onClick={() => {navigate('/dashboard/print-pdf',
                          { state: { textResult: result } }
                        )}}>Download PDF</Button>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <AppSaveResources category={serviceId} prompt_q={title} returned_answer={result} grade={grade}  subject={subject} />
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Container>
          </>
        }

        {isLoading &&
          <AppLoader />
        }
      </>
      
    );
  }
  