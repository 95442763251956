import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, Alert, Button, Typography, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useLoader from '../../../hooks/useLoader';


// ----------------------------------------------------------------------

export default function AppSubscriptionCall() {
    const navigate = useNavigate();
    const location = useLocation();
  
    return (
        <Grid container spacing={5} py={3}>
            <Grid item xs={12} md={12}>
                <Alert severity="warning">
                    <h4>Upgrade to PRO Plans to use this service </h4>
                </Alert>
            </Grid>
            <Grid item xs={12} md={12}>
            <Button onClick={() => {navigate('/dashboard/checkout')}} variant="contained">
                Upgrade
            </Button>
            </Grid>
        </Grid>
    );
}

