import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useLoader from '../../../hooks/useLoader';


// ----------------------------------------------------------------------

AppSaveResources.propTypes = {
    category: PropTypes.string,
    prompt_q: PropTypes.string,
    returned_answer: PropTypes.string,
    grade: PropTypes.string,
    subject: PropTypes.string,
};

export default function AppSaveResources({ category, prompt_q, returned_answer, grade, subject, ...other }) {
    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate()
    const { setLoading } = useLoader();
    
    const handleSave = async () => {
        setLoading(true)
        try {
            const user_id = localStorage.getItem("inktype");
            const response = await axiosPrivate.post(`/ai/store`, {
                user_id,
                category,
                prompt_q,
                returned_answer,
                grade,
                subject,
            });
            //console.log(response.data);
            setLoading(false)
        } catch (error) {
            if(error.message == "Request failed with status code 403"){
                navigate('/login', {state: {from: location}, replace: true})
            }
            setLoading(false)
            console.log(error);
        }
      };
  
    return (
    <LoadingButton fullWidth size="large" variant="contained" onClick={() => handleSave()}>
        Save
    </LoadingButton>
  );
}

