import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { TextField, InputLabel,MenuItem,FormControl,Select,
    Grid, Button, Container, Stack, Typography  } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { PaystackConsumer } from 'react-paystack';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useLocation } from 'react-router-dom';
import useResponsive from '../hooks/useResponsive';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import axios from 'axios';
import PackageList from '../components/dropdowns/Packages'
import { AppCheckoutPackages } from '../sections/@dashboard/app';


// ----------------------------------------------------------------------
  
  const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  }));

  const StyledSubSection = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop:'40px',
  }));

  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(3, 0),

  }));

  const StyledContentResult = styled('div')(({ theme }) => ({
    height:'auto',
    padding: theme.spacing(2),
    marginBottom:'20px',
  }));

  const StyledCheckout = styled('div')(({ theme }) => ({
    margin: 'auto',
    height:'auto',
    padding: theme.spacing(2, 2),
    backgroundColor: '#DCDEF5',
    borderRadius:'10px',
    marginTop:'40px',
  }));

  const DurationList = [
    {
      id: 1,
      title: 'Monthly',
    },
    {
      id: 2,
      title: 'Yearly',
    },
  ];


  
  // ----------------------------------------------------------------------

export default function Checkout() {
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');
  const axiosPrivate = useAxiosPrivate()
  const customerEmail = localStorage.getItem("customerEmail");
  const user_id = localStorage.getItem("inktype");
  const [packageprice, setPackagePrice] = useState(0); // Default price set to monthly subscription
  const [packages, setPackage] = useState(null);
  const [currency, setCurrency] = useState();
  const [currencySign, setCurrencySign] = useState("₦")
  const [country, setCountry] = useState("Nigeria")
  const [displayPrice, setDisplayPrice] = useState(null);
  const [subscriptionplan, setSubscriptionplan] = useState("Monthly"); // Default is Monthly
  const [displayCheckout, setCheckoutDisplay] = useState(false)

  const ip_apikey = '359fbb16df6248e1915f42848b403cac' //ipgeolocation.io

  useEffect(() => {
    getUserCountry()
    
    PackageList.map(item => {
      if(item.type === subscriptionplan){
        if(country == "Nigeria"){
          setPackagePrice(item.pricef)
          setPackage(item.id)
          setCurrency('NGN')
          setCurrencySign(item.currencyNGN)
        } else {
          setPackagePrice(item.pricefUSD)
          setPackage(item.id)
          setCurrency('USD')
          setCurrencySign(item.currencyUSD)
        }
      }
    }) 
 
    setDisplayPrice(currencyFormatter(packageprice))
  }, [packageprice]);

  const updateCustomerAccount = async () => {
      try {
      const response = await axiosPrivate.post(`/transactions/purchase`, {
          user_id,
          package_id: packages,
          amount: packageprice,
          currency: currency
      });
      
      } catch (error) {
          console.log(error.response.data);
      }
  };


  const config = {
      reference: (new Date()).getTime().toString(),
      email: customerEmail,
      amount: packageprice * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
      currency: currency,
      publicKey: 'pk_live_e36a13d614f0b9e6aa2d2a93701f369a28edc928',
  };

  const handleSuccess = (reference) => {
      updateCustomerAccount()
  };

  const handleClose = () => {
      console.log('closed')
  }

  const componentProps = {
      ...config,
      text: 'Pay with Paystack',
      onSuccess: (reference) => handleSuccess(reference),
      onClose: handleClose,
  };

  const currencyFormatter = (number) => {
    // Format the number to currency with commas and no decimal places
    const formattedNumber = number.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formattedNumber
  };


  const changePackagePrice = (pk) => {
    setSubscriptionplan(pk)

    PackageList.map(item => {
      if(item.type === pk){
        setPackagePrice(item.pricef)
        setPackage(item.id)
      }
    })
  }

  const getUserCountry = async () => {
    try {
      const response = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${ip_apikey}`);
      setCountry(response.data.country_name);
    } catch (error) {
      //console.error('Error fetching the country:', error);
    }
  };


    return (
      <>
        <Helmet>
          <title> Dashboard: Packages </title>
        </Helmet>

        {mdUp && (
          <StyledSection>
            <Typography variant="h4" sx={{ px: 3}}>
              {displayCheckout === false ? "Available Packages" : "Checkout"}
            </Typography>
            
          </StyledSection>
        )}
  
        <Container>
          <Grid container spacing={1}>
            {displayCheckout &&
              <Button  onClick={() => setCheckoutDisplay(false)}>  &larr; Back</Button>
            }
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
                <StyledSubSection>
                  <Grid item xs={12} sm={5} md={5}>
                    <FormControl fullWidth>
                        <InputLabel id="plan-label">Choose Plan</InputLabel>
                        <Select labelId="plan-label" value={subscriptionplan} label="Choose Plan" onChange={e => changePackagePrice(e.target.value)}>
                        {DurationList.map((option, index) => (
                            <MenuItem key={index} value={option.title} >
                            {option.title}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                  </Grid>
                </StyledSubSection>
            </Grid>
            {displayCheckout && 
              <Grid item xs={12} md={12}>
                {PackageList.map((pk, index) => (
                  subscriptionplan === pk.type &&
                  <Grid container spacing={12}>
                    <Grid item xs={12} md={4}>
                    <StyledCheckout>
                        <StyledContent>
                            <Typography variant="h5" sx={{ py: 1}}>
                                Paystack
                            </Typography>
                            <Stack spacing={3}>
                                <h3>{pk.title}</h3>
                                <p>{pk.subtitle}</p>
                                {country === 'Nigeria' ? (
                                    <h2>{pk.currencyNGN} {pk.pricef}</h2> 
                                  ) : (
                                    <h2>{pk.currencyUSD} {pk.pricefUSD}</h2> 
                                  )
                                } 
                            </Stack>
                        </StyledContent> 
                        <PaystackConsumer {...componentProps} >
                        {({initializePayment}) => <LoadingButton fullWidth size="large" variant="contained" onClick={() => initializePayment(handleSuccess, handleClose)}>Pay with Paystack</LoadingButton>}
                        </PaystackConsumer>
                      </StyledCheckout>
                    </Grid>
                  </Grid> 
                ))}
              </Grid>
            }
            </Grid>

          {!displayCheckout &&
            <Grid container spacing={12}>
              <Grid item xs={12} md={12}>
                <StyledContentResult>
                  <Grid container spacing={3}>
                    <section className="bsb-pricing-2 bg-light py-5 py-xl-8">
                      {/* <div className="container">
                        <div className="row justify-content-md-center">
                          <div className="col-12 col-md-7">
                            <h3 className="fs-6 text-secondary mb-2 text-uppercase text-center">Plans</h3>
                            <h2 className="display-5 mb-4 mb-md-5 text-center">We offer great pricing plans for everyone.</h2>
                            <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                          </div>
                        </div>
                      </div> */}

                      <div className="container">
                        <div className="row gy-5 gy-lg-0 gx-xl-5">
                          <div className="col-12 col-lg-6">
                            <div className="card border-0 border-bottom border-primary shadow-sm">
                              <div className="card-body p-4 p-xxl-5">
                                <h2 className="h4 mb-2">Free</h2>
                                <h4 className="display-3 fw-bold text-primary mb-0">{currencySign}0</h4>
                                <p className="text-secondary mb-4">Unlimited</p>
                                <ul className="list-group list-group-flush mb-4">
                                  <li className="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                    </svg>
                                    <span><strong>Unlimited</strong> use </span>
                                  </li>
                                  <li className="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                    </svg>
                                    <span><strong>Fine-tuned</strong> GPT  Prompt</span>
                                  </li>
                                  <li className="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                    </svg>
                                    <span><strong>32</strong> AI Tools</span>
                                  </li>
                                  <li className="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                    </svg>
                                    <span><strong>Resources</strong> Download Enabled</span>
                                  </li>
                                  <li className="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x text-danger" viewBox="0 0 16 16">
                                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                    <span>Team <strong>Collaboration</strong></span>
                                  </li>
                                  <li className="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x text-danger" viewBox="0 0 16 16">
                                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                    <span>Free <strong>Support</strong></span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          {subscriptionplan === "Monthly" && 
                            <div className="col-12 col-lg-6">
                              <div className="card border-0 border-bottom border-primary shadow-lg pt-md-4 pb-md-4 bsb-pricing-popular">
                                <div className="card-body p-4 p-xxl-5">
                                  <h2 className="h4 mb-2">Pro</h2>
                                  <h4 className="display-3 fw-bold text-primary mb-0">{currencySign}{displayPrice}</h4>
                                  <p className="text-secondary mb-4">Monthly</p>
                                  <ul className="list-group list-group-flush mb-4">
                                    <li className="list-group-item">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                      </svg>
                                      <span>Everything in <strong>Free</strong> Plans</span>
                                    </li>
                                    <li className="list-group-item">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                      </svg>
                                      <span>Presentation Generator</span>
                                    </li>
                                    <li className="list-group-item">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                      </svg>
                                      <span>Assessment Generator with Media File</span>
                                    </li>
                                    <li className="list-group-item">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                      </svg>
                                      <span>Science Lab Project Generator </span>
                                    </li>
                                    <li className="list-group-item">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                      </svg>
                                      <span>Social-Emotional Learning Plan Generator </span>
                                    </li>
                                  </ul>
                                  <LoadingButton fullWidth size="large" variant="contained" onClick={() => setCheckoutDisplay(true)}>Upgrade</LoadingButton>
                                </div>
                              </div>
                            </div>
                          }

                          {subscriptionplan === "Yearly" && 
                            <div className="col-12 col-lg-6">
                              <div className="card border-0 border-bottom border-primary shadow-lg pt-md-4 pb-md-4 bsb-pricing-popular">
                                <div className="card-body p-4 p-xxl-5">
                                  <h2 className="h4 mb-2">Pro</h2>
                                  <h4 className="display-3 fw-bold text-primary mb-0">{currencySign}{displayPrice} </h4>
                                  <p className="text-secondary mb-4">Yearly</p>
                                  <ul className="list-group list-group-flush mb-4">
                                    <li className="list-group-item">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                      </svg>
                                      <span>Everything in <strong>Free</strong> Plans</span>
                                    </li>
                                    <li className="list-group-item">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                      </svg>
                                      <span>Presentation Generator</span>
                                    </li>
                                    <li className="list-group-item">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                      </svg>
                                      <span>Assessment Generator with Media File</span>
                                    </li>
                                    <li className="list-group-item">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                      </svg>
                                      <span>Science Lab Project Generator </span>
                                    </li>
                                    <li className="list-group-item">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                      </svg>
                                      <span>Social-Emotional Learning Plan Generator </span>
                                    </li>
                                  </ul>
                                  <LoadingButton fullWidth size="large" variant="contained" onClick={() => setCheckoutDisplay(true)}>Upgrade</LoadingButton>
                                </div>
                              </div>
                            </div>
                          }

                        </div>
                      </div>
                    </section>
                  </Grid>
                </StyledContentResult>
              </Grid>
            </Grid>
          }
        </Container>
      </>
    );
  }
  