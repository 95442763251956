import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import examTypeList from '../../components/dropdowns/ExamType'
import difficultyList from '../../components/dropdowns/DifficultyLevel'
import questionTypeList from '../../components/dropdowns/QuestionType'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function PracticeTestGeneratorForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [examType, setExamType] = useState('');
  const [topic, setTopic] = useState('');
  const [difficultyLevel, setDifficultyLevel] = useState('')
  const [questionFormat, setQuestionFormat] = useState('');
  const [numberofQuestions, setNumberofQuestions] = useState('')
  const [timeLimit, setTimeLimit] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const serviceId = "practice test generator";

  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      examType,
      topic,
      difficultyLevel,
      questionFormat,
      numberofQuestions,
      timeLimit,
      additionalNotes,
      
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, difficultyLevel, topic, examType})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, difficultyLevel, topic, examType})
        if(error.response.data === "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
            <FormControl fullWidth>
                <InputLabel id="exam-label">Choose Exam Type</InputLabel>
                <Select labelId="exam-label" value={examType} label="Exam Type" onChange={e => setExamType(e.target.value)}>
                {examTypeList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
                </Select>
          </FormControl>
          <TextField name="Topic" label="Enter Subject/Topic" onChange={e => setTopic(e.target.value)} />
          <FormControl fullWidth>
                <InputLabel id="DifficultyLevel-label">Choose Difficulty Level</InputLabel>
                <Select labelId="DifficultyLevel-label" value={difficultyLevel} label="Difficulty Level" onChange={e => setDifficultyLevel(e.target.value)}>
                {difficultyList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
                </Select>
          </FormControl>
          <FormControl fullWidth>
                <InputLabel id="QuestionFormat-label">Choose Question Format</InputLabel>
                <Select labelId="QuestionFormat-label" value={questionFormat} label="Question Format" onChange={e => setQuestionFormat(e.target.value)}>
                {questionTypeList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
                </Select>
          </FormControl>
          <TextField name="NumberofQuestions" label="Specify Number of Questions" onChange={e => setNumberofQuestions(e.target.value)} />
          <TextField name="TimeLimit" label="Enter Time Limit" multiline="true" onChange={e => setTimeLimit(e.target.value)} />
          <TextField name="AdditionalNotes" label="(Optional) Include Additional Instructions or Guidelines" multiline="true" onChange={e => setAdditionalNotes(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
