import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import criterialList from '../../components/dropdowns/Criteria'
import ScoringscaleList from '../../components/dropdowns/Scoringscale'
import useLoader from '../../hooks/useLoader';
import { BorderStyle } from '@mui/icons-material';


const BoxSection = styled('div')(({ theme }) => ({
  width: '100%',
  padding: 5,
  borderWidth: 1,
  borderColor:'#BFBEC0',
  borderStyle:'solid',
  borderRadius: 8,
}));

// ----------------------------------------------------------------------

export default function MarkingschemeForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();
  const [isCriteria2, setIsCriteria2] = useState(false);
  const [isCriteria3, setIsCriteria3] = useState(false);
  const [isCriteria4, setIsCriteria4] = useState(false);
  const [counter, setCounter] = useState(1);

  const addCriteria = () => {
    if(counter === 2){
      setIsCriteria2(true);
    } else if(counter === 3){
      setIsCriteria3(true);
    } else if(counter === 4){
      setIsCriteria4(true);
    }
  }

  const [additionalNotes, setAdditionalNotes] = useState('');
  const [comments, setComments] = useState('');
  const [description, setDescription] = useState('');
  const [scoringScale, setScoringScale] = useState('');
  const [criteria, setCriteria] = useState('');
  const [weightage, setWeightage] = useState('');
  const [criteria2, setCriteria2] = useState('');
  const [weightage2, setWeightage2] = useState('');
  const [criteria3, setCriteria3] = useState('');
  const [weightage3, setWeightage3] = useState('');
  const [criteria4, setCriteria4] = useState('');
  const [weightage4, setWeightage4] = useState('');
  const serviceId = "markingscheme generator";

  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      additionalNotes,
      scoringScale,
      criteria,
      weightage,
      criteria2,
      weightage2,
      criteria3,
      weightage3,
      criteria4,
      weightage4,
      description,
      comments,

      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, criteria, scoringScale, description})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, criteria, scoringScale, description})
        if(error.response.data === "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
          <TextField name="description" label="Enter Details of the Assignment" multiline="true" onChange={e => setDescription(e.target.value)} />
          <BoxSection>
            <TextField name="criteria" label="Assessment Criteria 1: (e.g. content, organization, grammar)" multiline="true" onChange={e => setCriteria(e.target.value)} className="fullwidth mb-5" />
            <TextField name="weightage" label="Assign weightage for Criteria 1 (e.g. 20%, 50%)" onChange={e => setWeightage(e.target.value)} className="fullwidth" />
          </BoxSection>

          {isCriteria2 && <BoxSection>
            <TextField name="criteria" label="Assessment Criteria 2: (e.g. content, organization, grammar)" multiline="true" onChange={e => setCriteria2(e.target.value)} className="fullwidth mb-5" />
            <TextField name="weightage" label="Assign weightage for Criteria 2 (e.g. 20%, 50%)" onChange={e => setWeightage2(e.target.value)} className="fullwidth" />
          </BoxSection> }

          {isCriteria3 && <BoxSection>
            <TextField name="criteria" label="Assessment Criteria 3: (e.g. content, organization, grammar)" multiline="true" onChange={e => setCriteria3(e.target.value)} className="fullwidth mb-5" />
            <TextField name="weightage" label="Assign weightage for Criteria 3 (e.g. 20%, 50%)" onChange={e => setWeightage3(e.target.value)} className="fullwidth" />
          </BoxSection> }

          {isCriteria4 && <BoxSection>
            <TextField name="criteria" label="Assessment Criteria 4: (e.g. content, organization, grammar)" multiline="true" onChange={e => setCriteria4(e.target.value)} className="fullwidth mb-5" />
            <TextField name="weightage" label="Assign weightage for Criteria 4 (e.g. 20%, 50%)" onChange={e => setWeightage4(e.target.value)} className="fullwidth" />
          </BoxSection> }
          {!isCriteria4 && <Button variant="outlined" size="small" onClick={() => {addCriteria(setCounter(counter + 1));}}>Add Criteria</Button> }
          
          <TextField name="Scoringscale" label="Enter Scoring Scale (e.g., 0-5, 1-10)" onChange={e => setScoringScale(e.target.value)} />
          <TextField name="comments" label="Indicate if you want space for comments for each criterion" multiline="true" onChange={e => setComments(e.target.value)} />
          <TextField name="additionalNotes" label="Enter any additional guidelines or notes for the markers" multiline="true" onChange={e => setAdditionalNotes(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
