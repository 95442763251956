const gradeList = [
    { label: 'Pre School', value: 'Pre School' },
    { label: 'Early Years (EYFS)', value: 'Early Years' },
    { label: 'Nursery 1', value: 'Nursery 1' },
    { label: 'Nursery 2', value: 'Nursery 2' },
    { label: 'Grade 1', value: 'Grade 1' },
    { label: 'Grade 2', value: 'Grade 2' },
    { label: 'Grade 3', value: 'Grade 3' },
    { label: 'Grade 4', value: 'Grade 4' },
    { label: 'Grade 5', value: 'Grade 5' },
    { label: 'Grade 6', value: 'Grade 6' },
    { label: 'Grade 7', value: 'Grade 7' },
    { label: 'Grade 8', value: 'Grade 8' },
    { label: 'Grade 9', value: 'Grade 9' },
    { label: 'Grade 10', value: 'Grade 10' },
    { label: 'Grade 11', value: 'Grade 11' },
    { label: 'Grade 12', value: 'Grade 12' },
    { label: 'University', value: 'University' },
];

export default gradeList