import React, { useState } from 'react'
import { PDFViewer, Document, Page, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import MarkdownIt from 'markdown-it';
import Markdown from 'markdown-to-jsx';


    const styles = StyleSheet.create({
        page: {
            paddingTop:35,
            paddingBottom: 65,
            paddingHorizontal:35,
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        },
        pageNumber: {
            position: "absolute",
            fontSize: 12,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: "center",
            color: "grey"
        },
        text: {
            margin: 12,
            fontSize:14,
            textAlign: "justify",
            fontFamily: "Times-Roman"       
        }
    });

export default function PDFDownload() {
    const navigate = useNavigate();
    const {state} = useLocation();
    const { textResult } = state;

    const MyPDF = ({ markdownText }) => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                     <ReactMarkdown> {markdownText} </ReactMarkdown> 
                </View>
                <Text
                    style={styles.pageNumber}
                    render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </Page>
        </Document>
    );


    const markdownToHtml = (markdownText) => {
        const md = new MarkdownIt();
        return md.render(markdownText);
    };      
      

  return (
      <>
        <Document>
        <ReactMarkdown> {textResult} </ReactMarkdown>
            <Page size="A4" style={styles.page}>
            
                <View style={styles.section}>
                    <Text style={styles.text}> {textResult} </Text>
                     
                </View>
                <Text
                    style={styles.pageNumber}
                    render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </Page>
        </Document>
        <PDFDownloadLink document={<MyPDF markdownText={textResult} />} fileName="AIDocument">
            {({loading}) => (loading ? <button>loading document...</button> : <button>Download</button>)}
        </PDFDownloadLink>
    </>
  )
}
