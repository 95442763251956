import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import {Button,Dialog,DialogActions,DialogContent,DialogTitle,FormControl,Select,InputLabel} from '@mui/material';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useLoader from '../../../hooks/useLoader';
import AdminPackageList from '../../../components/dropdowns/AdminPackages'


const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 300
}));

const options = [
  'Details',
  'Change Status',
  'Update Plan',
  'Delete',
];

const ITEM_HEIGHT = 48;

AppMoreMenu.propTypes = {
    user: PropTypes.number.isRequired,
};

export default function AppMoreMenu({user, user_status}) {
  const navigate = useNavigate();
  const [isopen, setIsOpen] = useState(false);
  const [isopenTwo, setIsOpenTwo] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogAction, setDialogAction] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [packages, setPackage] = useState();
  const open = Boolean(anchorEl);
  const axiosPrivate = useAxiosPrivate()
  const { setServerResponse } = useLoader();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = (title, action) => {
    if(action == 'update'){
      setIsOpenTwo(true);
    }else{
      setDialogTitle(title);
      setDialogAction(action);
      setIsOpen(true);
    }
    
  };

  const handleDialogClose = () => {
    setIsOpen(false);
    setIsOpenTwo(false);
  };

  const handleAction = (action) => {
    switch (action) {
        case 'Details':
            handleClose()
            localStorage.setItem("action_user_id", user);
            navigate('/dashboard/account')
            break;
        case 'Delete':
            handleClickOpen('Are you sure you want to delete this record?', 'Delete' );
            break;
        case 'Change Status':
            handleClickOpen('Are you sure you want to continue with this action?', 'Change Status' );
            break;
        case 'Update Plan':
            handleClickOpen('Update plans', 'update');
            break;
        default:
            handleClose()
    }
  }

  const handleDialogSubmit = async (action) => {
    if(action == "Delete"){
      try {
        const response = await axiosPrivate.delete(`/profile/user/${user}`);
        setServerResponse(response.data)
        handleDialogClose()
      } catch (error) {
          console.log(error.response)
      }
    } else if(action == "Change Status"){
      try {
        const response = await axiosPrivate.put(`/profile/changestatus/${user}`, {
          status: user_status
        });
        setServerResponse(response.data)
        handleDialogClose()
      } catch (error) {
          console.log(error.response)
      }
    } else if(action == "Update Plan"){
      try {
        const response = await axiosPrivate.put(`/profile/changeplans/${user}`, {
          package: packages
        });
        setServerResponse(response.data)
        handleDialogClose()
      } catch (error) {
          console.log(error.response)
      }
    } else {
      handleDialogClose()
    }
    
  }


  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} selected={option === 'Delete'} onClick={() => handleAction(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>

        <Dialog open={isopen} onClose={handleDialogClose}>
            <DialogTitle> Confirm Action</DialogTitle>
                <DialogContent>
                    {dialogTitle}
                </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button variant="contained" onClick={() => { handleDialogSubmit(dialogAction)}}> {dialogAction} </Button>
            </DialogActions>
        </Dialog>


        <Dialog open={isopenTwo} onClose={handleDialogClose}>
            <DialogTitle> Change user plans</DialogTitle>
                <DialogContent>
                  <StyledContent>
                    <FormControl fullWidth>
                      <InputLabel id="package-label">Select Package</InputLabel>
                      <Select labelId="package-label" value={packages} label="Select Package" onChange={e => setPackage(e.target.value)}>
                      {AdminPackageList.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                          {option.title}
                          </MenuItem>
                      ))}
                      </Select>
                    </FormControl>
                  </StyledContent>
                </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button variant="contained" onClick={() => { handleDialogSubmit('Update Plan')}}> Submit </Button>
            </DialogActions>
        </Dialog>
    </div>
  );
}
