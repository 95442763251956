import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import supportResourcesList from '../../components/dropdowns/SupportResources'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function SpecialNeedsGeneratorForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [studentInformation, setStudentInformation] = useState('');
  const [age, setAge] = useState('');
  const [currentChallenges, setCurrentChallenges] = useState('');
  const [learningGoals, setLearningGoals] = useState('')
  const [supportResources, setSupportResources] = useState('');
  const [PreferredTeachingMethods, setPreferredTeachingMethods] = useState('');
  const [familyInvolvement, setFamilyInvolvement] = useState('');
  const [progressMonitoring, setProgressMonitoring] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const serviceId = "specialneeds intervention generator";

  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      studentInformation,
      age,
      currentChallenges,
      learningGoals,
      supportResources,
      PreferredTeachingMethods,
      familyInvolvement,
      progressMonitoring,
      additionalNotes,
      
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, currentChallenges, learningGoals, studentInformation})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, currentChallenges, learningGoals, studentInformation})
        if(error.response.data === "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
          <TextField name="StudentInformation" label="Enter Student Name" onChange={e => setStudentInformation(e.target.value)} />
          <TextField name="age" label="Enter Student Age" onChange={e => setAge(e.target.value)} />
          <TextField name="CurrentChallenges" label="Describe the specific challenges the student is facing in the learning environment" onChange={e => setCurrentChallenges(e.target.value)} />
          <TextField name="LearningGoals" label="Learning Goals (e.g. improving communication skills, enhancing social interaction)" onChange={e => setLearningGoals(e.target.value)} />
          <FormControl fullWidth>
            <InputLabel id="supportresources-label">Support Resources</InputLabel>
            <Select labelId="supportresources-label" value={supportResources} label="Support Resources" onChange={e => setSupportResources(e.target.value)}>
              {supportResourcesList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField name="PreferredTeachingMethods" label="Indicate any preferred teaching methods or strategies for the student" onChange={e => setPreferredTeachingMethods(e.target.value)} />
          <TextField name="FamilyInvolvement" label="Describe the level of involvement and support from the student's family" multiline="true" onChange={e => setFamilyInvolvement(e.target.value)} />
          <TextField name="ProgressMonitoring" label="Explain how the student's progress will be monitored and assessed" multiline="true" onChange={e => setProgressMonitoring(e.target.value)} />
          <TextField name="AdditionalNotes" label="Include any other specific information relevant to the intervention" multiline="true" onChange={e => setAdditionalNotes(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
