import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import gradeList from '../../components/dropdowns/Grade'
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function ModuleGeneratorForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [title, setTitle] = useState('');
  const [duration, setDuration] = useState('');
  const [grade, setGrade] = useState('');
  const [objectives, setObjectives] = useState('');
  const [additionalguidelines, setAdditionalguidelines] = useState('');
  const [classroomsize, setClassroomSize] = useState('');
  const [resources, setResources] = useState('');
  const [classroomsetting, setClassroomSetting] = useState('');
  const serviceId = "icebreaker activity";

  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      title,
      objectives,
      grade,
      duration,
      classroomsize,
      additionalguidelines,
      resources,
      classroomsetting,

      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, objectives, grade, title})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, objectives, grade, title})
        if(error.response.data === "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
          <TextField name="title" label="Enter Topic/Subject" onChange={e => setTitle(e.target.value)} />

          <FormControl fullWidth>
            <InputLabel id="grade-label">Grade Level</InputLabel>
            <Select labelId="grade-label" value={grade} label="Grade Level" onChange={e => setGrade(e.target.value)}>
              {gradeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField name="classroomsize" label="Enter the Number of Students in the Classroom" onChange={e => setClassroomSize(e.target.value)} />
          <TextField name="classroomsetting" label="Describe the Physical Classroom or Virtual Setting for the activity" onChange={e => setClassroomSetting(e.target.value)} />
          <TextField name="materials" label="Indicate if any Specific Classroom Materials/Resources are available" onChange={e => setResources(e.target.value)} />
          <TextField name="duration" label="Enter the Duration available for the Icebreaker Activity " onChange={e => setDuration(e.target.value)} />
          <TextField name="objective" label="Enter Educational Objective (e.g. fostering teamwork, introducing a new topic)" onChange={e => setObjectives(e.target.value)} />
          <TextField name="additionalguidelines" label="Include any Specific Classroom Rules or Guidelines" multiline="true" onChange={e => setAdditionalguidelines(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
