import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthProvider';
import { LoaderProvider } from './context/LoaderProvider'
import { CurrentPlanProvider } from './context/CurrentPlanProvider'

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <AuthProvider>
        <LoaderProvider>
            <CurrentPlanProvider>
                <App />
            </CurrentPlanProvider>
        </LoaderProvider>
    </AuthProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
