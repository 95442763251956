import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const adminNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  /*{
    title: 'user',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'product',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  }, */
  {
    title: 'all users',
    path: '/dashboard/users',
    icon: icon('ic_user'),
  },
  {
    title: 'my team',
    path: '/dashboard/team',
    icon: icon('ic_user'),
  },
  {
    title: 'users feedback',
    path: '/dashboard/feedback',
    icon: icon('ic_cart'),
  },
  {
    title: 'all tools',
    path: '/dashboard/alltools',
    icon: icon('alt-grid-svgrepo-com'),
  },
  {
    title: 'lesson plan generator',
    path: '/dashboard/lessonplan',
    icon: icon('alt-book-svgrepo-com'),
  },
  {
    title: 'curriculum generator',
    path: '/dashboard/curriculum',
    icon: icon('alt-center-align-svgrepo-com'),
  },
  {
    title: 'handout generator',
    path: '/dashboard/handout',
    icon: icon('alt-clipboard-svgrepo-com'),
  },
  {
    title: 'Powerpoint Presentation',
    path: '/dashboard/powerpointslide',
    icon: icon('alt-center-align-svgrepo-com'),
  },
  {
    title: 'Text to Speech',
    path: '/dashboard/texttospeech',
    icon: icon('alt-clipboard-svgrepo-com'),
  },
  /* {
    title: 'login',
    path: '/login',
    icon: icon('ic_lock'),
  },
  {
    title: 'Not found',
    path: '/404',
    icon: icon('ic_disabled'),
  }, */
];

export default adminNavConfig;
