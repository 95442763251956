import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import supportResourcesList from '../../components/dropdowns/SupportResources'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function LanguageTranslatorForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [sourceLanguage, setSourceLanguage] = useState('');
  const [targetLanguage, setTargetLanguage] = useState('');
  const [texttoTranslate, setTexttoTranslate] = useState('')
  const [context, setContext] = useState('');
  const serviceId = "language translator";

  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      sourceLanguage,
      targetLanguage,
      texttoTranslate,
      context,
      
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, texttoTranslate, targetLanguage, sourceLanguage})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, texttoTranslate, targetLanguage, sourceLanguage})
        if(error.response.data === "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
          <TextField name="SourceLanguage" label="Enter Source Language" onChange={e => setSourceLanguage(e.target.value)} />
          <TextField name="TargetLanguage" label="Enter Target Language" onChange={e => setTargetLanguage(e.target.value)} />
          <TextField name="TexttoTranslate" label="Enter Text to Translate" multiline="true" onChange={e => setTexttoTranslate(e.target.value)} />
          <TextField name="Context" label="(Optional) Include any Context or Purpose for the translation" multiline="true" onChange={e => setContext(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
