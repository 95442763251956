import axios from 'axios';

const BASE_URL = 'https://api.aiteacha.com/api';

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    mode: 'no-cors',
});