import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';


// ----------------------------------------------------------------------

export default function Logout() {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("inktype")
    localStorage.removeItem("customerEmail")

    navigate('/login')
  }, []);


  return (
    <div></div>
  );
}
