import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import ageGroupList from '../../components/dropdowns/AgeGroup'
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function RhymesForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [ageGroup, setAgeGroup] = useState('');
  const [theme, setTheme] = useState('');
  const [numberOfVerse, setNumberOfVerse] = useState('');
  const [keywords, setKeywords] = useState('');
  const serviceId = "rhymes";

  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      ageGroup,
      theme,
      numberOfVerse,
      keywords,

      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, numberOfVerse, ageGroup, theme})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, numberOfVerse, ageGroup, theme})
        if(error.response.data == "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
          <FormControl fullWidth>
            <InputLabel id="grade-label">Age Group</InputLabel>
            <Select labelId="ageGroup-label" value={ageGroup} label="Age Group" onChange={e => setAgeGroup(e.target.value)}>
              {ageGroupList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <TextField name="theme" label="Theme (Animals in the jungle)" onChange={e => setTheme(e.target.value)} />
          <TextField name="numberOfVerse" label="Number Of Verse" multiline="false" onChange={e => setNumberOfVerse(e.target.value)} />
          <TextField name="keywords" label="Keywords (Lion, monkey, elephant, roar)" multiline="true" onChange={e => setKeywords(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
