import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { Link, Stack, IconButton, InputAdornment, TextField, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import useAuth from '../../../hooks/useAuth';
import axios from '../../../api/axios'

// ----------------------------------------------------------------------

const RegisterForm = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  
  const userRef = useRef();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState('');
  const [isError, setIsError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCfPassword, setShowCfPassword] = useState(false);
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [firstname, setFirstName] = useState();
  const [lastname, setLastName] = useState();
  const [organization, setOrganization] = useState();
  const [password, setPassword] = useState();
  const [password_confirmation, setPasswordConfirm] = useState();

  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
      setErrMsg('');
  }, [email, password])


  const handleLogin = async () => {
    try {
      const response = await axios.post(`auth/register`, 
      JSON.stringify({firstname, lastname, email, phone, organization, password, password_confirmation }),
      {
        headers: { 'Content-Type': 'application/json' }
      });
        console.log("USER ",response.data);
        localStorage.setItem("regstatus", response.data);
        navigate('/login')
    } catch (error) {
        setIsError(true)
        console.log(error);
        setErrMsg(error.response.data);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        {isError &&
            <Alert ref={errRef} severity="error"> {errMsg} </Alert>
        }
        
        <TextField name="firstname" label="First Name" 
        ref={userRef}
        autoComplete="off"
        onChange={e => setFirstName(e.target.value)} />

        <TextField name="lastname" label="Last Name" 
        autoComplete="off"
        onChange={e => setLastName(e.target.value)} />

        <TextField name="email" label="Email Address" 
        autoComplete="off"
        onChange={e => setEmail(e.target.value)} />

        <TextField name="phone" label="Phone Number" 
        autoComplete="off"
        onChange={e => setPhone(e.target.value)} />

        <TextField name="organization" label="School/Organization" 
        autoComplete="off"
        onChange={e => setOrganization(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          onChange={e => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          name="confirmpassword"
          label="Confirm Password"
          onChange={e => setPasswordConfirm(e.target.value)}
          type={showCfPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowCfPassword(!showCfPassword)} edge="end">
                  <Iconify icon={showCfPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleLogin} sx={{ my: 2 }}>
        Register
      </LoadingButton>
    </>
  );
}

export default RegisterForm
