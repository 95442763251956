import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useCurrentPlan from '../hooks/useCurrentPlan';
import Iconify from '../components/iconify';

import {
  AppNewsUpdate,
  AppOrderTimeline,
  AppWidgetSummary,
  AppPayWidgetSummary
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const { auth } = useAuth();
  const { setCurrentPlan } = useCurrentPlan()
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const user_id = localStorage.getItem("inktype");
  const [walletdata, setWalletData] = useState('');
  const [orderhistory, setOrderHistory] = useState(null);
  const [savedresource, setSavedResource] = useState(null);


  useEffect(() => {
    localStorage.removeItem("regstatus");
    retrieveWallet()
    retrieveHistory()
    retrieveSavedResources()
  }, []);

  const retrieveWallet = async () => {
    try {
      const response = await axiosPrivate.get(`/profile/wallet/${user_id}`);
      setWalletData(response.data[0]) 
      setCurrentPlan(response.data[0].name)
    } catch (error) {
      if(error.response?.status == 401){
        navigate('/login', {state: {from: location}, replace: true})
      }
    }
  };


  const retrieveHistory = async () => {
    try {
      const response = await axiosPrivate.get(`/transactions/history/${user_id}`);
      setOrderHistory(response.data) 
    } catch (error) {
      console.log(error);
    }
  };


  const retrieveSavedResources = async () => {
    try {
      const response = await axiosPrivate.get(`/ai/resources/${user_id}`);
      setSavedResource(response.data) 
      console.log(response.data)
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>
      <Helmet>
        <title> Dashboard | AI Teacha </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Unit Balance" total={walletdata.unit_balance} icon={'ant-design:wallet-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Current Package" desc={walletdata.name} color="info" icon={'ant-design:safety-certificate-filled'} />
          </Grid>

          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Unlimited Access" desc="AI Teacha (Free)" color="warning" icon={'ant-design:plus-circle-filled'} />
          </Grid> */}

          <Grid item xs={12} sm={6} md={3}>
            <AppPayWidgetSummary title="Upgrade" link="/dashboard/curriculum" color="warning" icon={'ant-design:plus-circle-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Saved Items" total={savedresource?.length != null ? savedresource.length : 0} color="error" icon={'ant-design:container-filled'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {savedresource
              ?
                <AppNewsUpdate
                  title="Saved Resources"
                  list={savedresource.slice(0, 5).map((item, index) => ({
                    id: faker.datatype.uuid(),
                    title: item.prompt,
                    description: item.returned_answer.substr(3, 30)+'...',
                    image: `/assets/images/covers/cover_${index + 1}.jpg`,
                    postedAt: item.created_at,
                  }))}
                />
              :
                <AppNewsUpdate
                  title="Saved Resources"
                  list={[]}
                />
            }
            
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {orderhistory
              ?
                <AppOrderTimeline
                  title="Order History"
                  list={orderhistory.slice(0, 5).map((item, index) => ({
                    id: faker.datatype.uuid(),
                    title: item.description,
                    type: `order${index + 1}`,
                    time: item.transaction_date,
                  }))}
                />
              :
                <AppOrderTimeline
                  title="Order History"
                  subheader="No record found"
                  list={[]}
                />
            }
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
