import { createContext, useState } from "react";

const LoaderContext = createContext({});

export const LoaderProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [serverResponse, setServerResponse] = useState(null);

    return (
        <LoaderContext.Provider value={{loading, setLoading, serverResponse, setServerResponse}}>
            {children}
        </LoaderContext.Provider>
    )
}

export default LoaderContext;