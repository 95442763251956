import { createContext, useState } from "react";

const CurrentPlanContext = createContext({});

export const CurrentPlanProvider = ({ children }) => {
    const [currentplan, setCurrentPlan] = useState(null);

    return (
        <CurrentPlanContext.Provider value={{currentplan, setCurrentPlan}}>
            {children}
        </CurrentPlanContext.Provider>
    )
}

export default CurrentPlanContext;