import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select,Alert, Input } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import gradeList from '../../components/dropdowns/Grade'
import questionTypeList from '../../components/dropdowns/QuestionType'
import mediaTypeList from '../../components/dropdowns/MediaType'
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function VideoAudioAssessmentForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [subject, setSubject] = useState('');
  const [grade, setGrade] = useState('');
  const [topic, setTopic] = useState('');
  const [numberOfQuestion , setNumberOfQuestion] = useState(5);
  const [objective, setObjective] = useState('');
  const [assessmetType, setAssessmentType] = useState('');
  const [duration, setDuration] = useState('10 minutes');
  const [mediaType, setMediaType] = useState('');
  const [fileUpload, setFileUpload] = useState('');
  const [mediaLink, setMediaLink] = useState('');
  const [notes, setNotes] = useState('');
  const [errorMessage, setErrorMessage] = useState(null)
  const serviceId = "video audio assessment";

  const handleSubmit = () => {
    setLoading(true)

    let formData = new FormData();
    formData.append('subject', subject)
    formData.append('grade', grade)
    formData.append('topic', topic)
    formData.append('numberOfQuestion', numberOfQuestion)
    formData.append('objective', objective)
    formData.append('assessmetType', assessmetType)
    formData.append('duration', duration)
    formData.append('mediaType', mediaType)
    formData.append('audio', fileUpload)
    formData.append('mediaLink', mediaLink)
    formData.append('notes', notes)
    formData.append('user_id', auth?.userId)
    formData.append('serviceId', serviceId)

    axiosPrivate.post(`/ai/teacher`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, subject, grade, topic})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, subject, grade, topic})
        if(error.response.data == "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ['audio/wav','audio/mpeg','audio/webm','audio/mp3','audio/x-m4a','video/mp4','video/webm','video/wav','video/mpeg'];
      if (allowedTypes.includes(file.type)) {
        setErrorMessage(null)
        setFileUpload(file);
      } else {
        setErrorMessage("Invalid file type. Allowed types: mp3, mp4, mpeg, mpga, m4a, wav, and webm")
        event.target.value = null; 
      }
    }
  }

  return (
    <>
        {errorMessage && <Alert mb={2} severity="error">{errorMessage}</Alert> }
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
          <InputLabel id="file-label" className="file-label">Choose File</InputLabel>
          <Input name="fileUpload" label="Upload Video/Audio File" type="file" variant="filled" onChange={handleFileChange} 
          accept=".mp3, .mp4, .mpeg, .mpga, .m4a, .wav, .webm" className="file-input" />

          <TextField name="subject" label="Enter Subject (Math, Science, History)" onChange={e => setSubject(e.target.value)} />
          
          <FormControl fullWidth>
            <InputLabel id="grade-label">Grade Level</InputLabel>
            <Select labelId="grade-label" value={grade} label="Grade Level" onChange={e => setGrade(e.target.value)}>
              {gradeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <TextField name="topic" label="Enter Topic" onChange={e => setTopic(e.target.value)} />
          {/* <TextField name="objective" label="Learning Objectives" onChange={e => setObjective(e.target.value)} /> */}
          
          <FormControl fullWidth>
            <InputLabel id="assessmentType-label">Assessment Type</InputLabel>
            <Select labelId="assessmentType-label" value={assessmetType} label="Assessment Type" onChange={e => setAssessmentType(e.target.value)}>
              {questionTypeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField name="numberOfQuestion" label="Number of Questions" onChange={e => setNumberOfQuestion(e.target.value)} />
          {/* <TextField name="duration" label="Duration (Minutes)" onChange={e => setDuration(e.target.value)} /> */}
          
          {/* <FormControl fullWidth>
            <InputLabel id="mediaType-label">MediaType Type</InputLabel>
            <Select labelId="mediaType-label" value={mediaType} label="Media Type" onChange={e => setMediaType(e.target.value)}>
              {mediaTypeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          {/* <TextField name="mediaLink" label="Media Link (YouTube or other) Optional" onChange={e => setMediaLink(e.target.value)} />
          <TextField name="notes" label="Additional Notes/Instructions" multiline="true" onChange={e => setNotes(e.target.value)} /> */}
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
