import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import gradeList from '../../components/dropdowns/Grade'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function BehaviorInterForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [studentinformation, setStudentinformation] = useState('');
  const [age, setAge] = useState('');
  const [grade, setGrade] = useState('');
  const [behavior, setBehavior] = useState('');
  const [triggeranalysis, setTriggeranalysis] = useState('');
  const [goal, setGoal] = useState('');
  const [interventionstrategies, setInterventionstrategies] = useState('');
  const [timeframe, setTimeframe] = useState('');
  const [resources, setResources] = useState('');
  const [progressmonitoring, setProgressmonitoring] = useState('');
  const [emergencyprocedures, setEmergencyprocedures] = useState('');
  const serviceId = "behavior intervention generator";

  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      studentinformation,
      age,
      grade,
      behavior,
      triggeranalysis,
      goal,
      interventionstrategies,
      timeframe,
      resources,
      progressmonitoring,
      emergencyprocedures,

      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, behavior, goal, studentinformation})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, behavior, goal, studentinformation})
        if(error.response.data === "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
          <TextField name="studentinformation" label="Enter Student Name" onChange={e => setStudentinformation(e.target.value)} />
          <TextField name="age" label="Enter Student Age" onChange={e => setAge(e.target.value)} />
          <FormControl fullWidth>
            <InputLabel id="grade-label">Grade</InputLabel>
            <Select labelId="grade-label" value={grade} label="Grade Level" onChange={e => setGrade(e.target.value)}>
              {gradeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField name="behavior" label="Describe the Specific Behavior that needs Intervention and Provide Context" onChange={e => setBehavior(e.target.value)} />
          <TextField name="triggeranalysis" label="Identify Potential Triggers or Situations that lead to the Behavior" onChange={e => setTriggeranalysis(e.target.value)} />
          <TextField name="goal" label="Enter the Desired Outcome or Goal of the Behavior Intervention" onChange={e => setGoal(e.target.value)} />
          <TextField name="resources" label="Specify any available Support Systems, Resources, or Professionals involved" onChange={e => setResources(e.target.value)} />
          <TextField name="interventionstrategies" label="Describe any Preferred Intervention Strategies, if applicable" multiline="true" onChange={e => setInterventionstrategies(e.target.value)} />
          <TextField name="timeframe" label="Indicate the Expected Duration or Timeframe for the Behavior Intervention" multiline="true" onChange={e => setTimeframe(e.target.value)} />
          <TextField name="progressmonitoring" label="Explain how progress will be Monitored and Assessed" multiline="true" onChange={e => setProgressmonitoring(e.target.value)} />
          <TextField name="emergencyprocedures" label="Include any Emergency Procedures or Crisis Management Plans, if needed." multiline="true" onChange={e => setEmergencyprocedures(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
