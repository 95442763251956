import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function TeamAddForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [inviteeemail, setInviteeEmail] = useState('');
  const status = 'active';

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    axiosPrivate.post(`/team`, { 
      host_id : auth?.userId,
      invitee_email: inviteeemail,
      status,

      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      if(result === "Team member added successfully"){
        props.onSubmit({result, status:true})
      } else {
        props.onSubmit({result, status:false})
      }
      
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      props.onSubmit({result: error.response.data, status:false})
      if(error.response.data == "Forbidden"){
        navigate('/login', {state: {from: location}, replace: true})
      }
    });
  };


  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField name="inviteeemail" label="Enter Team Member Email" onChange={e => setInviteeEmail(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Submit
        </LoadingButton>
      </form>
    </>
  );
}
