import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import ageGroupList from '../../components/dropdowns/AgeGroup'
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function CoachSportPracticeForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [ageGroup, setAgeGroup] = useState('');
  const [sport, setSport] = useState('');
  const [skillLevel, setSkillLevel] = useState('');
  const [duration , setDuration] = useState('');
  const serviceId = "coach sport practice";

  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      ageGroup,
      sport,
      skillLevel,
      duration,

      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, sport, ageGroup, skillLevel})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, sport, ageGroup, skillLevel})
        if(error.response.data == "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
            <TextField name="sport" label="Sport" onChange={e => setSport(e.target.value)} />
          <FormControl fullWidth>
            <InputLabel id="age-label">Age Group</InputLabel>
            <Select labelId="ageGroup-label" value={ageGroup} label="Age Group" onChange={e => setAgeGroup(e.target.value)}>
              {ageGroupList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <TextField name="skillLevel" label="Skill Level (Beginner/Intermediate/Advanced)" onChange={e => setSkillLevel(e.target.value)} />
          <TextField name="duration" label="Duration (Minutes)" multiline="false" onChange={e => setDuration(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
