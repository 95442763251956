import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import gradeList from '../../components/dropdowns/Grade'
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function GroupworkForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [grade, setGrade] = useState('');
  const [subject, setSubject] = useState('');
  const [activityTopic, setActivityTopic] = useState('');
  const [groupSize, setGroupSize ] = useState('');
  const [duration, setDuration] = useState('');
  const serviceId = "group work";

  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      grade,
      subject,
      activityTopic,
      groupSize,
      duration,

      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, activityTopic, grade, subject})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, activityTopic, grade, subject})
        if(error.response.data == "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
            <FormControl fullWidth>
            <TextField name="Activity Topic" label="Activity Topic (Eg. The Industrial Revolution)" multiline="false" onChange={e => setActivityTopic(e.target.value)} />
                <InputLabel id="grade-label">Choose Grade</InputLabel>
                <Select labelId="grade-label" value={grade} label="Grade" onChange={e => setGrade(e.target.value)}>
                {gradeList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
          
          <TextField name="subject" label="Subject (Eg. History)" onChange={e => setSubject(e.target.value)} />
          <TextField name="groupSize" label="Group Size (Eg. 4 students per group)" onChange={e => setGroupSize(e.target.value)} />
          <TextField name="duration" label="Duration (Class Periods)" multiline="true" onChange={e => setDuration(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
