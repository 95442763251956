import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader,Alert,
TextField,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from '@mui/material';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth';
import useLoader from '../../../hooks/useLoader';

// ----------------------------------------------------------------------

AppTeamMembers.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};


export default function AppTeamMembers({ title, subheader, list, ...other }) {
  return (
      <>
        <Card {...other}>
        <CardHeader title={title} subheader={subheader} />

        <Scrollbar>
            <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
            {list.map((news) => (
                <NewsItem key={news.id} news={news} />
            ))}
            </Stack>
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 2, textAlign: 'right' }}>
            {/* <Button size="small" color="inherit" endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}>
            View all
            </Button> */}
        </Box>
        </Card>
    </>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string,
  }),
};

function NewsItem({ news }) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading, setServerResponse } = useLoader();

  const { image, title, description, id } = news;
  const [open, setOpen] = useState(false);
  const [isopen, setIsOpen] = useState(false);
  const [unit, setUnit] = useState('');

    const handleClickOpen = (email) => {
        localStorage.setItem("action_user_email", email);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteOpen = (id) => {
      localStorage.setItem("action_user_id", id);
      setIsOpen(true);    
    };
  
    const handleDialogClose = () => {
      setIsOpen(false);
    };

    const handleSubmit = (e) => {
      e.preventDefault()
      const email = localStorage.getItem("action_user_email");
      setLoading(true)
      axiosPrivate.post(`/team/giftunit`, { 
        user_id : auth?.userId,
        package_id: 1, 
        unit, 
        team_member_email: email
      })
      .then(res => {
        const result = res.data
        setServerResponse(result)         
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        setServerResponse(error.response.data)   
        if(error.response.data == "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
      });
    };
    
    const handleDialogDelete = async () => {
      const id = localStorage.getItem("action_user_id")
      try {
        const response = await axiosPrivate.delete(`/team/${id}`);
        setServerResponse(response.data)
        handleDialogClose()
      } catch (error) {
          console.log(error.response)
      }
    }

  return (
      <>
        <Stack direction="row" alignItems="center" spacing={2}>
        <Box component="img" alt={title} src={image} sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }} />

        <Box sx={{ minWidth: 240, flexGrow: 1 }}>
            <Link color="inherit" variant="subtitle2" underline="hover" noWrap>
            {title}
            </Link>

            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {description}
            </Typography>
        </Box>

        <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
            <Button variant="contained" onClick={() => handleClickOpen(description)} sx={{mr:2}}>
                Gift Unit
            </Button>
            <Button variant="outlined" color="error" onClick={() => handleDeleteOpen(id)}>
              Delete
            </Button>
        </Typography>
        </Stack>
    
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Gift Member Unit</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Unit Value"
                    type="number"
                    fullWidth
                    variant="standard"
                    onChange={e => setUnit(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>


        <Dialog open={isopen} onClose={handleDialogClose}>
            <DialogTitle> Confirm Action</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this item
                </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button variant="contained" onClick={handleDialogDelete}> Continue </Button>
            </DialogActions>
        </Dialog>
    </>
  );
}
