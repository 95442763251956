import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { styled } from '@mui/material/styles';
import { Grid, Button, Container, Link, Typography, Alert, Table,TableBody,TableHead,
  TableCell,TableContainer,TableRow,Dialog,DialogActions,DialogContent,DialogTitle } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useNavigate, useLocation } from 'react-router-dom';
import useResponsive from '../hooks/useResponsive';
import { FeedbackForm } from '../sections/forms';
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import AppLoader from '../components/loader/AppLoader';
import useLoader from '../hooks/useLoader';


// ----------------------------------------------------------------------
  
  const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }));

  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 780,
    margin: 'auto',
    minHeight: '5vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(5, 0),
  }));

  const StyledContentData = styled('div')(({ theme }) => ({
    margin: 'auto',
    minHeight: 480,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 5),
  }));
  
  // ----------------------------------------------------------------------

  const columns = [
    { id: 'screenshot', label: 'Screenshot' },
    { id: 'date', label: 'Date'},
    { id: 'action', label: 'Action'},
    { id: 'action2', label: ''},
  ];

export default function Feedback() {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const mdUp = useResponsive('up', 'md');
  const user_id = localStorage.getItem("inktype");
  const [isopen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resultStatus, setResultStatus] = useState(false);
  const [result, setResult] = useState(null)
  const [feedbackData, setFeedbackData] = useState(null)
  
  const [displayForm, setDisplayForm] = useState(false);
  const { loading, serverResponse, setServerResponse } = useLoader();

  useEffect(() => {
    retrieveFeedback()
    setIsLoading(loading)
  }, [loading]);

  const getData = ({result, status}) => {
    setResult(result)
    setResultStatus(status)
  }

  const retrieveFeedback = async () => {
    try {
      const response = await axiosPrivate.get(`/admin/feedbacks`);
      setFeedbackData(response.data) 
    } catch (error) {
      if(error.response?.status == 401){
        navigate('/login', {state: {from: location}, replace: true})
      }
    }
  };

  const handleDialogDelete = async () => {
    const id = localStorage.getItem("action_user_id")
    try {
      const response = await axiosPrivate.delete(`/admin/feedback/${id}`);
      setServerResponse(response.data)
      handleDialogClose()
    } catch (error) {
        console.log(error.response)
    }
  }

  const handleClickOpen = (id) => {
    localStorage.setItem("action_user_id", id);
    setIsOpen(true);    
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

    return (
      <>
        <Dialog open={isopen} onClose={handleDialogClose}>
            <DialogTitle> Confirm Action</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this item
                </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button variant="contained" onClick={handleDialogDelete}> Continue </Button>
            </DialogActions>
        </Dialog>

        {!isLoading && 
          <>
            <Helmet>
              <title> Dashboard: Feedback </title>
            </Helmet>
    
            {mdUp && (
                <StyledSection>
                    <Typography variant="h3" sx={{ px: 3}}>
                        Users Feedback
                    </Typography>

                    <Button variant="contained" onClick={() => setDisplayForm(!displayForm)}>
                        Add Feedback
                    </Button>
              </StyledSection>
            )}
    
            <Container>
              <Grid container spacing={12}>
                <Grid item xs={12} md={12}>
                    {displayForm && 
                        <Grid item xs={12} md={12}>
                            <StyledContent>
                                {result && !resultStatus && <Alert severity="error">{result}</Alert>}
                                {result && resultStatus && <Alert severity="success">{result}</Alert>}
                                <FeedbackForm onSubmit={getData} />
                            </StyledContent>
                        </Grid>
                    }
        
                    <Grid item xs={12} md={12}>
                        <StyledContentData>
                        {serverResponse != null && <Alert severity="info">{serverResponse}</Alert>}
                        
                        <TableContainer component={Paper}>                            
                            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                <TableHead>
                                  <TableRow>
                                    {columns.map((column) => (
                                      <TableCell
                                        key={column.id}
                                      >
                                        {column.label}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                {feedbackData?.map((row) => (
                                    <TableRow key={row.id}>
                                      <TableCell component="th" scope="row">
                                          {row.image_upload}
                                      </TableCell>
                                      <TableCell>
                                          {row.created_at}
                                      </TableCell>
                                      <TableCell align="right">
                                        <Link href={`https://api.aiteacha.com/admin/feedbackimages/`+row.image_upload} color="primary" target="_blank">
                                          Preview
                                        </Link>
                                      </TableCell>
                                      <TableCell align="right">
                                        <Button variant="outlined" color="error" onClick={() => {handleClickOpen(row.id)}}>
                                            Delete
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                ))}
                                
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </StyledContentData>                        
                    </Grid>
                </Grid>
              </Grid>
            </Container>
          </>
        }

        {isLoading &&
          <AppLoader />
        }
      </>
      
    );
  }
  