import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Grid, Button, Container, Stack, Typography, Tab, Box } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useLocation } from 'react-router-dom';
import useResponsive from '../hooks/useResponsive';
import { HandoutForm, FileToTextForm } from '../sections/forms';
import { AppSaveResources } from '../sections/@dashboard/app';
import AppLoader from '../components/loader/AppLoader';
import useLoader from '../hooks/useLoader';


// ----------------------------------------------------------------------
  
  const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  }));

  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 680,
    margin: 'auto',
    minHeight: '5vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  }));

  const StyledContentResult = styled('div')(({ theme }) => ({
    margin: 'auto',
    minHeight: 480,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2, 5),
    borderWidth: 2,
    borderColor:'#BFBEC0',
    borderStyle:'solid',
    marginTop:'90px',
    overflowY:'scroll',
  }));
  
  // ----------------------------------------------------------------------

export default function Handout() {
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');
  const [isLoading, setIsLoading] = useState(false);
  const { loading } = useLoader();
  const filetext = "You are a handout generator, generate a handout ";

  const serviceId = "handout generator";
  const [result, setResult] = useState(null)
  const [subject, setSubject] = useState(null)
  const [grade, setGrade] = useState(null)
  const [title, setTitle] = useState(null)
  const [tab, setTab] = useState('1');
  

  useEffect(() => {
    setIsLoading(loading)
  }, [loading]);


  const getData = ({result, title, subject, grade}) => {
    setResult(result)
    setSubject(subject)
    setGrade(grade)
    setTitle(title)
  }


  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

    return (
      <>
      {!isLoading && 
        <>
          <Helmet>
            <title> Dashboard: Handout Generator </title>
          </Helmet>

          {mdUp && (
            <StyledSection>
              <Typography variant="h3" sx={{ px: 3}}>
                Handout Generator
              </Typography>
            </StyledSection>
          )}

          <Container>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <StyledContent>
                  <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Use Form" value="1" />
                        <Tab label="Use Media" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <HandoutForm onSubmit={getData} />
                    </TabPanel>

                    <TabPanel value="2">
                      <FileToTextForm content={filetext} serviceIdType={serviceId} isTitle={true} isGrade={true} isSubject={true} onSubmit={getData} />
                    </TabPanel>
                  </TabContext>
                </StyledContent>
              </Grid>

              <Grid item xs={12} md={8}>
                <StyledContentResult>
                  { result && <div id="resultDoc"><ReactMarkdown>{result}</ReactMarkdown></div> }
                  { !result && <h3>Result:</h3>}
                </StyledContentResult>
                
                {result &&
                  <Grid container spacing={12} py={3}>
                    <Grid item xs={6} md={4}>
                      <Button onClick={() => {navigate('/dashboard/print-pdf',
                        { state: { textResult: result } }
                      )}}>Download PDF</Button>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <AppSaveResources category={serviceId} prompt_q={title} returned_answer={result} grade={grade}  subject={subject} />
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Container>
        </>
      }

      {isLoading &&
        <AppLoader />
      }
      </>
      
    );
  }
  