import { useState, useEffect } from 'react';
import FormData from 'form-data';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, Grid, TextField, Alert, Input, InputLabel, MenuItem, FormControl, Select, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import gradeList from '../../components/dropdowns/Grade'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import useLoader from '../../hooks/useLoader';
import useCurrentPlan from '../../hooks/useCurrentPlan';
import uploadImg from '../../styles/upload-icon.png'
import { AppSubscriptionCall } from '../@dashboard/app';

// ----------------------------------------------------------------------

export default function FileToTextForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const { currentplan, setCurrentPlan } = useCurrentPlan();

  const [errorMessage, setErrorMessage] = useState(null)
  const [audio, setAudio] = useState(null);
  const [grade, setGrade] = useState('');
  const [title, setTitle] = useState('');
  const [subject, setSubject] = useState('');
  const [curriculum, setCurriculum] = useState('');
  const [questionNo, setQuestionNo] = useState(3);

  const filetext = props.title;
  const serviceId = "file to text";

  useEffect(() => {
    retrieveWallet()
  })

  const handleSubmit = () => {
    setLoading(true)

    let formData = new FormData();
    formData.append('filetext', filetext)
    formData.append('audio', audio)
    formData.append('title', title)
    formData.append('subject', subject)
    formData.append('curriculum', curriculum)
    formData.append('questionNo', questionNo)
    formData.append('grade', grade)
    formData.append('user_id', auth?.userId)
    formData.append('serviceId', serviceId)
    formData.append('serviceIdType', props.serviceIdType)

    axiosPrivate.post(`/ai/teacher`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
        const result = res.data
        props.onSubmit({result, subject:'from image', grade:'from image', title: filetext})
        setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      // navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, subject:'from image', grade:'from image', title: filetext})
        if(error.response.data == "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSelectedName(file.name);

    if (file) {
      const allowedTypes = ['application/pdf','image/jpeg','image/png','image/gif','image/jpg','image/webp'];
      if (allowedTypes.includes(file.type)) {
        setErrorMessage(null)
        setAudio(file);
      } else {
        setErrorMessage("Invalid file type. Allowed types: pdf, jpeg, png, gif, jpg, and webp")
        event.target.value = null; 
      }
    }
  }
  
  const retrieveWallet = async () => {
    const user_id = auth?.userId;
    try {
      const response = await axiosPrivate.get(`/profile/wallet/${user_id}`);
      setCurrentPlan(response.data[0].name)
    } catch (error) {
      if(error.response?.status == 401){
        //console.log("Error: ", error.response)
      }
    }
  };

  return (
    <>
      {currentplan === 'Free' ? (

        <AppSubscriptionCall />
      
      ) : (

        <>
          {errorMessage && <Alert mb={2} severity="error">{errorMessage}</Alert> }
          <form onSubmit={checkAccount}>
            <Stack spacing={3} mb={4}>
              {props.isTitle &&
                <TextField name="title" label="Enter Title/Topic" onChange={e => setTitle(e.target.value)} />
              }
              
              {props.isSubject &&
                <TextField name="subject" label="Enter Subject" onChange={e => setSubject(e.target.value)} />
              }

              {props.isGrade &&
                <FormControl fullWidth>
                  <InputLabel id="grade-label">Grade Level</InputLabel>
                  <Select labelId="grade-label" value={grade} label="Grade Level" onChange={e => setGrade(e.target.value)}>
                    {gradeList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }

              {props.isQuestionNo &&
                <TextField name="questionNo" label="No. Of Question" onChange={e => setQuestionNo(e.target.value)} style={{ width: '100%' }} />
              }

              {props.isCurriculum &&
                <TextField name="curriculum" label="Curriculum Type (e.g. UK Curriculum)" onChange={e => setCurriculum(e.target.value)} />
              }

              <div className="upload-wrapper">
                <div className="upload-wrapper-in">
                  <div className="file-upload">
                    <img src={uploadImg} alt="upload" />
                    <h3>{selectedName || "Click box to upload"}</h3>
                    <p>Maximun file size 10mb</p>
                    <Input name="audio" label="Upload File" type="file" variant="filled" onChange={handleFileChange} 
                    accept=".pdf, .jpeg, .png, .jpg, .gif, .webp" />
                  </div>
                </div>
              </div>
              <p className="upload-info">Upload resource material you want to use to generate content</p> 
              <p className="upload-info"><strong>Note: </strong> Only PDF or Image File is accepted</p>            
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
              Generate
            </LoadingButton>
          </form>
        </>
      )}
    </>
  );
}
