import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/iconify';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import gradeList from '../../components/dropdowns/Grade'
import contentLengthList from '../../components/dropdowns/ContentLength'
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function HandoutForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();
  const [isSubtopic2, setIsSubtopic2] = useState(false);
  const [isSubtopic3, setIsSubtopic3] = useState(false);
  const [isSubtopic4, setIsSubtopic4] = useState(false);
  const [counter, setCounter] = useState(1);

  const addSubtopic = () => {
    if(counter === 2){
      setIsSubtopic2(true);
    } else if(counter === 3){
      setIsSubtopic3(true);
    } else if(counter === 4){
      setIsSubtopic4(true);
    }
  }

  const [title, setTitle] = useState('');
  const [subject, setSubject] = useState('');
  const [grade, setGrade] = useState('');
  const [subtopic, setSubtopic] = useState('');
  const [subtopic2, setSubtopic2] = useState(null);
  const [subtopic3, setSubtopic3] = useState(null);
  const [subtopic4, setSubtopic4] = useState(null);
  const [description, setDescription] = useState('');
  const serviceId = "handout generator";

  const handleSubmit = () => {
    setLoading(true)
    let topics = subtopic +', '+ subtopic2 +', '+ subtopic3 +', '+ subtopic4 

    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      title,
      subject,
      grade,
      subtopic: topics,
      description,

      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, title, subject, grade})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, title, subject, grade})
        if(error.response.data == "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
          <TextField name="title" label="Lesson Title" onChange={e => setTitle(e.target.value)} />
          <TextField name="subject" label="Subject" onChange={e => setSubject(e.target.value)} />
          <FormControl fullWidth>
            <InputLabel id="grade-label">Grade</InputLabel>
            <Select labelId="grade-label" value={grade} label="Grade Level" onChange={e => setGrade(e.target.value)}>
              {gradeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField name="subtopic" label="Sub Topic" onChange={e => setSubtopic(e.target.value)} />
          {isSubtopic2 && 
            <TextField name="subtopic2" label="Sub Topic Two" onChange={e => setSubtopic2(e.target.value)} />
          }

          {isSubtopic3 && 
            <TextField name="subtopic3" label="Sub Topic Three" onChange={e => setSubtopic3(e.target.value)} />
          }

          {isSubtopic4 && 
            <TextField name="subtopic4" label="Sub Topic Four" onChange={e => setSubtopic4(e.target.value)} />
          }
          {!isSubtopic4 && <Button variant="outlined" size="small" onClick={() => {addSubtopic(setCounter(counter + 1));}}>Add Criteria</Button> }
          
          <TextField name="description" label="Include any additional notes, guidelines, or special considerations" multiline="true" onChange={e => setDescription(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
