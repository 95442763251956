import { useState } from 'react';
import FormData from 'form-data';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, Input, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function FeedbackForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [photo, setPhoto] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null)
  const status = 'active';

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    let formData = new FormData();
    formData.append('photo', photo)
    formData.append('user_id', auth?.userId)

    axiosPrivate.post(`/admin/feedback`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
        const result = res.data
        props.onSubmit({result, status:true})
        setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      // navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ['image/jpeg','image/png','image/gif','image/bmp','image/tiff','image/webp','image/wbmp','image/avif'];
      if (allowedTypes.includes(file.type)) {
        setErrorMessage(null)
        setPhoto(file);
      } else {
        setErrorMessage("Invalid file type. Allowed types: jpeg png, gif, bmp, tiff, webp, wbmp and avif")
        event.target.value = null; 
      }
    }
  }


  return (
    <>
        {errorMessage && <Alert mb={2} severity="error">{errorMessage}</Alert> }
      <form onSubmit={handleSubmit}>
        <Stack spacing={3}>
            <Input name="photo" label="Upload Photo" type="file" variant="filled" onChange={handleFileChange} 
          accept=".jpeg .png, .gif, .bmp, .tiff, .webp, .wbmp, .avif" />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Submit
        </LoadingButton>
      </form>
    </>
  );
}
