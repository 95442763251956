import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select, 
    FormLabel,FormGroup,FormControlLabel,Checkbox} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import gradeList from '../../components/dropdowns/Grade'
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function SdgIntegrationForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [grade, setGrade] = useState('');
  const [subject, setSubject] = useState('');
  const [sdg, setSdg] = useState({
      sdg1: false,
      sdg2: false,
      sdg3: false,
      sdg4: false,
      sdg5: false,
      sdg6: false,
      sdg7: false,
      sdg8: false,
      sdg9: false,
      sdg10: false,
      sdg11: false,
      sdg12: false,
      sdg13: false,
      sdg14: false,
      sdg15: false,
      sdg16: false,
      sdg17: false
  });
  const serviceId = "sdg integration";

  const {sdg1, sdg2, sdg3, sdg4, sdg5, sdg6, sdg7, sdg8, sdg9,
        sdg10, sdg11, sdg12, sdg13, sdg14, sdg15, sdg16, sdg17} = sdg

  const handleSubmit = () => {
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      grade,
      subject,
      sdg,

      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      props.onSubmit({result, sdg, grade, subject})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, sdg, grade, subject})
        if(error.response.data == "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };


  const handleChange = (event) => {
    setSdg({
      ...sdg,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
          <FormControl fullWidth>
            <InputLabel id="grade-label">Age Group</InputLabel>
            <Select labelId="grade-label" value={grade} label="Choose Grade" onChange={e => setGrade(e.target.value)}>
              {gradeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <TextField name="subject" label="Enter Subject" onChange={e => setSubject(e.target.value)} />
          
          <FormControl sx={{ m: 3 }} component="sdg" variant="standard">
                <FormLabel component="sdg">Select Specific SDG</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={sdg1} onChange={handleChange} name="sdg1" />}
                        label="SDG 1 - No Poverty"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg2} onChange={handleChange} name="sdg2" />}
                        label="SDG 2 - Zero Hunger"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg3} onChange={handleChange} name="sdg3" />}
                        label="SDG 3 - Good Health and Well-being"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg4} onChange={handleChange} name="sdg4" />}
                        label="SDG 4 - Quality Education"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg5} onChange={handleChange} name="sdg5" />}
                        label="SDG 5 - Gender Equality"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg6} onChange={handleChange} name="sdg6" />}
                        label="SDG 6 - Clean Water and Sanitation"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg7} onChange={handleChange} name="sdg7" />}
                        label="SDG 7 - Affordable and Clean Energy"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg8} onChange={handleChange} name="sdg8" />}
                        label="SDG 8 - Decent Work and Economic Growth"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg9} onChange={handleChange} name="sdg9" />}
                        label="SDG 9 - Industry, Innovation, and Infrastructure"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg10} onChange={handleChange} name="sdg10" />}
                        label="SDG 10 - Reduced Inequality"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg11} onChange={handleChange} name="sdg11" />}
                        label="SDG 11 - Sustainable Cities and Communities"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg12} onChange={handleChange} name="sdg12" />}
                        label="SDG 12 - Responsible Consumption and Production"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg13} onChange={handleChange} name="sdg13" />}
                        label="SDG 13 - Climate Action"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg14} onChange={handleChange} name="sdg14" />}
                        label="SDG 14 - Life Below Water"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg15} onChange={handleChange} name="sdg15" />}
                        label="SDG 15 - Life on Land"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg16} onChange={handleChange} name="sdg16" />}
                        label="SDG 16 - Peace, Justice, and Strong Institutions"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={sdg17} onChange={handleChange} name="sdg17" />}
                        label="SDG 17 - Partnerships for the Goals"
                    />
                </FormGroup>
            </FormControl>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
