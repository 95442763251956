import { useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Alert } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../components/logo';
import { NewPasswordForm } from '../sections/auth/login';
import AppLoader from '../components/loader/AppLoader';
import useLoader from '../hooks/useLoader';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function NewPassword() {
  const mdUp = useResponsive('up', 'md');
  const regstatus = localStorage.getItem("regstatus") || null;
  const [isLoading, setIsLoading] = useState(false);
  const { loading, serverResponse } = useLoader();


  useEffect(() => {
    setIsLoading(loading)
  }, [loading]);


  return (
    <>
    {!isLoading &&
      <>
      <Helmet>
        <title> Reset Password </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h3" gutterBottom sx={{ mb: 6 }}>
              Reset Password 
            </Typography>

            {serverResponse != null && <Alert severity="error">{serverResponse}</Alert>}
            <NewPasswordForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </> 
    }

    {isLoading &&
      <AppLoader />
    }
    </> 
  );
}
