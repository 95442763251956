import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../api/axios';
import useAuth from './useAuth';


const useRefreshToken = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setAuth } = useAuth();

    const refresh = async () => {
        try{
            const response = await axios.get('auth/refresh', {
                withCredentials: true,
            });
            setAuth(prev => {
                // console.log(JSON.stringify(prev));
                // console.log(response.data.accessToken);
                // console.log("ID ", response.data.id);
                return { 
                    ...prev, 
                    userId: response.data.id, 
                    role: response.data.role,
                    accessToken: response.data.accessToken 
                }
            });
    
            let data = {
                accessToken: response.data.accessToken,
                role: response.data.role,
                userId: response.data.id
            }
            return data;

        } catch (error){
            // console.log(error.response?.status)
            if(error.response?.status == 401){
                navigate('/login', {state: {from: location}, replace: true})
            }
        }
    }
    return refresh;
    
};

export default useRefreshToken;