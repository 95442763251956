import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import useAuth from '../../../hooks/useAuth';
import useLoader from '../../../hooks/useLoader';
import axios from '../../../api/axios'


// ----------------------------------------------------------------------

const NewPasswordForm = () => {
  const { setLoading, setServerResponse } = useLoader();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState();

  const handleSubmit = async () => {
    const email = localStorage.getItem("resetpasswordemail");
    setLoading(true)
    try {
      const response = await axios.put(`/auth/passwordreset/${email}/${password}`);

        setServerResponse(null)
        setLoading(false)

        if(response.data === "Found"){
            setServerResponse("Password was changed successfully!")
            navigate('/login')
        }    
    } catch (error) { 
        console.log(error.response.data);
        setServerResponse(error.response.data)
        setLoading(false)
    }
  };


  return (
    <>
        <Stack spacing={3}>
            <h3>New Password</h3>
            <TextField
                name="password"
                label="Enter Password"
                onChange={e => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
            />
            
            <LoadingButton fullWidth size="large" type="button" variant="contained" onClick={handleSubmit}>
                Submit
            </LoadingButton>     
        </Stack>
    </>
  );
}

export default NewPasswordForm
