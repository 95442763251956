import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import useAuth from '../../../hooks/useAuth';
import useLoader from '../../../hooks/useLoader';
import axios from '../../../api/axios'


// ----------------------------------------------------------------------

const LoginForm = () => {
  const { setAuth } = useAuth();
  const { setLoading, setServerResponse } = useLoader();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  
  const userRef = useRef();
  const errRef = useRef();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  useEffect(() => {
    userRef.current.focus();
  }, [])

  const handleLogin = async () => {
    localStorage.removeItem("regstatus");
    setLoading(true)
    try {
      const response = await axios.post(`auth/login`, 
      JSON.stringify({ email, password }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      });
        console.log("AUTH ",response.data);
        const accessToken = response.data.accessToken;
        const refreshToken = response.data.refreshToken;
        const tokenDec = jwt_decode(accessToken)
        const userId = (tokenDec.id).toString()
        const userEmail = (tokenDec.uemail).toString() 
        const role = (tokenDec.role).toString() 
        setAuth({ userEmail, userId, role, accessToken });
        localStorage.setItem("customerEmail", userEmail);
        localStorage.setItem("inktype", userId);
        setLoading(false)
        setServerResponse(null)
        navigate(from, { replace: true });
        
        console.log('User logged in');
    } catch (error) { 
        // console.log(error.response.data);
        setServerResponse(error.response.data)
        setLoading(false)
    }
  };

  return (
    <>
      <Stack spacing={3}>

        <TextField name="email" label="Email address" 
        ref={userRef}
        autoComplete="off"
        onChange={e => setEmail(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          onChange={e => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <div><Checkbox name="remember" label="Remember me" /> Remember Me </div>
        <Link variant="subtitle2" underline="hover" href="/forgotpassword">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleLogin}>
        Login
      </LoadingButton>
    </>
  );
}

export default LoginForm
