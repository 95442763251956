import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { styled } from '@mui/material/styles';
import { Grid, Button, Container } from '@mui/material';


const StyledContentResult = styled('div')(({ theme }) => ({
    margin: 'auto',
    minHeight: 480,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2, 5),
    borderWidth: 2,
    borderColor:'#BFBEC0',
    borderStyle:'solid',
    marginTop:'30px',
}));

export default function PrintPDF() {
    const navigate = useNavigate();
    const {state} = useLocation();
    const { textResult } = state;

    const handlePrint = () => {
        const section = document.getElementById('ResourceDoc');
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<html><head><title>Print</title></head><body>');
        printWindow.document.write(section.innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();

        // window.print();
    };
      

    return (
        <>
            <Button variant="contained" className="dontPrint" onClick={handlePrint}>
                Start Download
            </Button>
            <Container id="ResourceDoc">
                <Grid container spacing={12}>
                    <Grid item xs={12} md={8}>
                        <StyledContentResult>
                            { textResult && <div><ReactMarkdown>{textResult}</ReactMarkdown></div> }
                        </StyledContentResult>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
