import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, TextField, InputLabel,MenuItem,FormControl,Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth';
import useLoader from '../../hooks/useLoader';

// ----------------------------------------------------------------------

export default function NewsletterGeneratorForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { setLoading } = useLoader();

  const [schoolInformation, setSchoolInformation] = useState('');
  const [schoolAddress, setSchoolAddress] = useState('');
  const [schoolContact, setSchoolContact] = useState('');
  const [newsletterDate, setNewsletterDate] = useState('');
  const [sessionType, setSessionType] = useState('')
  const [keyHighlights, setKeyHighlights] = useState('');
  const [upcomingInitiatives, setUpcomingInitiatives] = useState('');
  const [feeUpdates, setFeeUpdates] = useState('');
  const [announcements, setAnnouncements] = useState('');
  const [otherInformation, setOtherInformation] = useState('');
  const serviceId = "newsletter generator";

  const handleSubmit = () => {
    let schoolInfo = schoolInformation + ', address: ' + schoolAddress + ', contact: '+ schoolContact  
    setLoading(true)
    axiosPrivate.post(`/ai/teacher`, { 
      user_id : auth?.userId,
      serviceId,
      schoolInfo,
      newsletterDate,
      sessionType,
      keyHighlights,
      upcomingInitiatives,
      feeUpdates,
      announcements,
      otherInformation,
      
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      const result = res.data
      console.log("Res ", result)
      props.onSubmit({result, sessionType, newsletterDate, schoolInformation})
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error);
      navigate('/login', {state: {from: location}, replace: true})
    });
  };


  const checkAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
        const user_id = auth?.userId;
        const response = await axiosPrivate.get(`/ai/check/${user_id}/${serviceId}`);
        
        await handleSubmit()
    } catch (error) {
        setLoading(false)
        props.onSubmit({result: error.response.data, sessionType, newsletterDate, schoolInformation})
        if(error.response.data === "Forbidden"){
          navigate('/login', {state: {from: location}, replace: true})
        }
    }
  };

  return (
    <>
      <form onSubmit={checkAccount}>
        <Stack spacing={3}>
          <TextField name="SchoolInformation" label="Enter School Name" onChange={e => setSchoolInformation(e.target.value)} />
          <TextField name="SchoolAddress" label="Enter School Address" onChange={e => setSchoolAddress(e.target.value)} />
          <TextField name="SchoolContact" label="Enter School Contact" onChange={e => setSchoolContact(e.target.value)} />
          <TextField name="newsletterdate" label="Enter Newsletter Date (e.g. weekly, monthly)." onChange={e => setNewsletterDate(e.target.value)} />
          <TextField name="sessionType" label="Indicate the Term or Semester the Newsletter Covers" onChange={e => setSessionType(e.target.value)} />
          <TextField name="KeyHighlights" label="List the key Highlights or Events that occurred during the Term (comma separated)" onChange={e => setKeyHighlights(e.target.value)} />
          <TextField name="UpcomingInitiatives" label="Describe any new Initiatives/Programs for the upcoming Term or Semester" multiline="true" onChange={e => setUpcomingInitiatives(e.target.value)} />
          <TextField name="FeeUpdates" label="Information about school fees updates if applicable" multiline="true" onChange={e => setFeeUpdates(e.target.value)} />
          <TextField name="Announcements" label="Announcements (e.g. upcoming parent-teacher meetings, school closures)" multiline="true" onChange={e => setAnnouncements(e.target.value)} />
          <TextField name="OtherInformation" label="Other Information (Optional)" multiline="true" onChange={e => setOtherInformation(e.target.value)} />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5 }}>
          Generate
        </LoadingButton>
      </form>
    </>
  );
}
