import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import {Typography,Table,TableBody,TableHead,TableCell,TableRow,Alert,
    Grid,Container,Button,TextField,Dialog,DialogActions,DialogContent,
    DialogTitle} from '@mui/material';
import { AppPasswordReset } from '../sections/@dashboard/app'
import { useNavigate, useLocation } from 'react-router-dom';
import useResponsive from '../hooks/useResponsive';
import AppLoader from '../components/loader/AppLoader';
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useLoader from '../hooks/useLoader';
import  '../styles/custom.css';


// ----------------------------------------------------------------------
  
  const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }));

  const StyledContent = styled('div')(({ theme }) => ({
    display: 'block',
    margin:15,
    padding: theme.spacing(2, 0),
  }));
  
  // ----------------------------------------------------------------------

export default function AccountSettings() {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()
  const mdUp = useResponsive('up', 'md');
  const { setLoading, setServerResponse, loading, serverResponse } = useLoader();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null)
  
  const serviceId = "assessment questions generator";
  const [result, setResult] = useState(null)
  const [subject, setSubject] = useState(null)
  const [grade, setGrade] = useState(null)
  const [type, setType] = useState(null)

  useEffect(() => {
    retrieveUsers()
    //setIsLoading(loading)
  }, [loading]);

  const retrieveUsers = async () => {
    try {
      setIsLoading(true)
      const id = localStorage.getItem("action_user_id");
      const response = await axiosPrivate.get(`/profile/accountdetails/${id}`);
      console.log("uuss ", response.data[0])
      setUserData(response.data[0]) 
      setIsLoading(false)
    } catch (error) {
      if(error.response?.status == 401){
        setIsLoading(false)
        navigate('/login', {state: {from: location}, replace: true})
      }
    }
  };

    return (
      <>
      {!isLoading &&
        <>
          <Helmet>
            <title> Dashboard: Assessment Generator </title>
          </Helmet>

          {mdUp && (
            <StyledSection>
              <Typography variant="h3" sx={{ px: 3}}>
                Account Settings
              </Typography>

              <Button variant="contained" onClick={() => navigate('/dashboard/app')}>
                  Dashboard
              </Button>
            </StyledSection>
          )}
    
          <Container>
            <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                  {serverResponse != null && <Alert severity="info">{serverResponse}</Alert>}
                    <StyledContent>
                        <div className="card bg-c-default order-card">
                            <div className="card-block">
                                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                    <TableBody>
                                        {userData
                                        ?
                                            <>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <b>Full Name</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        {userData.name}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <b>Email</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        {userData.email}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <b>Phone</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        {userData.phone}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Active
                                                    </TableCell>
                                                    <TableCell>
                                                        {userData.active_status}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        : 
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Something went wrong! No record
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </StyledContent>
                </Grid>

                <Grid xs={12} md={4}>
                  <Grid container>
                    <Grid item xs={12}>
                        <StyledContent>
                            <div className="card bg-c-blue order-card">
                                <div className="card-block">
                                    <h6 className="m-b-20">Wallet</h6>
                                    <h2 className="text-right"><i className="fa fa-refresh f-left"></i><span>{userData?.unit_balance}</span></h2>
                                    <p className="m-b-0">Available Unit<span className="f-right"> </span></p>
                                </div>
                            </div>
                        </StyledContent>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledContent>
                            <div className="card bg-c-pink order-card">
                                <div className="card-block">
                                    <h6 className="m-b-20">Transactions</h6>
                                    <h2 className="text-right"><i className="fa fa-credit-card f-left"></i><span>{userData?.total_debit_amount}</span></h2>
                                    <p className="m-b-0">Used units<span className="f-right"> </span></p>
                                </div>
                            </div>
                        </StyledContent>
                    </Grid>
                </Grid>
              </Grid>  

              <Grid  xs={12} md={12}>
                  <AppPasswordReset user={userData?.id} />
              </Grid>                    
            </Grid>
          </Container>
        </>
      }

      {isLoading &&
        <AppLoader />
      }
      </>
      
    );
  }
  