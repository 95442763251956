const AdminPackageList = [
  {
    id: 1,
    title: 'Free',
    subtitle: 'No seat available',
    unit: 'unlimited',
    code: '#223377',
    currencyNGN: '₦',
    pricef: '0',
    currencyUSD: '$',
    pricefUSD: '0',
    type:"Free"
  },
  {
    id: 2,
    title: 'Pro - (Monthy)',
    subtitle: 'Unlimited seats available',
    unit: '100',
    code: '#de2633',
    currencyNGN: '₦',
    pricef: 5000,
    currencyUSD: '$',
    pricefUSD: 5,
    type:"Monthly"
  },
  {
    id: 3,
    title: 'Pro - (Yearly)',
    subtitle: 'Unlimited seats available',
    unit: '100',
    code: '#de2633',
    currencyNGN: '₦',
    pricef: 55000,
    currencyUSD: '$',
    pricefUSD: 50,
    type:"Yearly"
  },
];

export default AdminPackageList